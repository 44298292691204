import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { News } from '@api';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  Bold,
  ClassicEditor,
  Essentials,
  FontColor,
  FontSize,
  Italic,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  Underline,
} from 'ckeditor5';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'app-create-news-dialog',
  standalone: true,
    imports: [
        MatDialogContent,
        MatFormField,
        MatLabel,
        MatDialogActions,
        FormsModule,
        MatDialogClose,
        ReactiveFormsModule,
        MatDialogTitle,
        NgxFileDropModule,
        ReactiveFormsModule,
        CKEditorModule,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        DatePipe,
        MatError,
        MatInput,
        MatCheckbox,
    ],
  templateUrl: './create-news-dialog.component.html',
  styleUrl: './create-news-dialog.component.scss',
})
export class CreateNewsDialogComponent implements OnInit {
  readonly fb = inject(FormBuilder);
  @Input() news?: News;
  @Output() updateForm = new EventEmitter();
  form!: FormGroup;
  currentDate!: Date;
  image?: any;

  public Editor = ClassicEditor;
  public config = {
    language: { ui: 'fr' },
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'link',
      '|',
      'fontSize',
      '|',
      'fontColor',
      '|',
      'mediaEmbed',
      '|',
    ],
    plugins: [
      Bold,
      Italic,
      Underline,
      List,
      Link,
      FontSize,
      FontColor,
      Essentials,
      Mention,
      Paragraph,
      MediaEmbed,
    ],
  };

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.currentDate = new Date();
    this.form = this.fb.group({
      id: new FormControl(this.news?.id),
      name: new FormControl(this.news?.name, [Validators.required]),
      uuid: new FormControl(this.news?.uuid, [Validators.required]),
      alwaysDisplay: new FormControl(this.news?.alwaysDisplay),
      content: new FormControl(this.news?.content, [Validators.required]),
      blob: new FormControl(),
    });
    this.updateForm.emit(this.form);
    this.form.valueChanges.subscribe((val) => {
      this.updateForm.emit(this.form);
    });
  }

  displayFn(val: any): string {
    return val?.name;
  }

  public dropped(files: NgxFileDropEntry[]) {
    // Is it a file?
    if (
      files[0].fileEntry.isFile &&
      files[0].fileEntry.name.match(`^.*\\.(jpg|jpeg|png)$`)
    ) {
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        let objectURL = URL.createObjectURL(file);
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.form.patchValue({ uuid: file.name, blob: file });
      });
    }
  }

  removeFile() {
    this.form.patchValue({ uuid: undefined, blob: undefined });
  }
}
