import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[appTooltipTruncated]',
  standalone: true,
})
export class TooltipTruncatedDirective {
  private resizeSubscription: Subscription | undefined;
  private textElement: HTMLElement | undefined;

  constructor(
    private el: ElementRef,
    private matTooltip: MatTooltip,
    private renderer: Renderer2
  ) {
    // Initialiser le tooltip sur l'élément hôte
    this.matTooltip.position = 'above';
    this.renderer.addClass(this.el.nativeElement, 'mat-tooltip-trigger');
    this.matTooltip.ngAfterViewInit(); // Initialiser le MatTooltip
  }

  ngAfterViewInit() {
    // Trouver l'élément <p> à l'intérieur de l'élément hôte
    this.textElement = this.el.nativeElement.querySelector('p');

    if (!this.textElement) {
      console.warn(
        "Aucun élément <p> trouvé à l'intérieur de l'élément avec appTooltipIfTruncated."
      );
      return;
    }

    // Vérifier si le texte est tronqué
    this.updateTooltip();

    // Re-vérifier lors des changements de taille de fenêtre
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => {
      this.updateTooltip();
    });
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  private updateTooltip() {
    if (!this.textElement) {
      this.matTooltip.disabled = true;
      return;
    }

    const isTruncated = this.isTruncated(this.textElement);

    if (isTruncated && this.textElement.textContent) {
      this.matTooltip.message = this.textElement.textContent.trim();
      this.matTooltip.disabled = false;
    } else {
      this.matTooltip.message = '';
      this.matTooltip.disabled = true;
    }
  }

  private isTruncated(element: HTMLElement): boolean {
    return (
      element.scrollWidth > element.clientWidth ||
      element.scrollHeight > element.clientHeight
    );
  }
}
