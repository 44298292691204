import {Component, inject} from '@angular/core';
import {FoldersStore} from "@app/store/folders.store";
import {NgClass} from "@angular/common";
import {Element} from "@api";

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {
  store = inject(FoldersStore);

  select(node: Element) {
    this.store.selectFolder(node);
  }
}
