import {Component, inject, model} from '@angular/core';
import {
    CreateDocumentDialogComponent
} from "@app/shared/create-widget-dialog/create-document-dialog/create-document-dialog.component";
import {
    CreateFolderDialogComponent
} from "@app/shared/create-widget-dialog/create-folder-dialog/create-folder-dialog.component";
import {
    CreateLinkDialogComponent
} from "@app/shared/create-widget-dialog/create-link-dialog/create-link-dialog.component";
import {
    CreateNewsDialogComponent
} from "@app/shared/create-widget-dialog/create-news-dialog/create-news-dialog.component";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {RightFormComponent} from "@app/shared/right/right-form/right-form.component";
import {FormGroup} from "@angular/forms";
import {Rule} from "@api";

@Component({
  selector: 'app-add-document-dialog',
  standalone: true,
    imports: [
        CreateDocumentDialogComponent,
        CreateFolderDialogComponent,
        CreateLinkDialogComponent,
        CreateNewsDialogComponent,
        MatDialogContent,
        MatStep,
        MatStepLabel,
        MatStepper,
        RightFormComponent
    ],
  templateUrl: './add-document-dialog.component.html',
  styleUrl: './add-document-dialog.component.scss'
})
export class AddDocumentDialogComponent {
  currentForm?: FormGroup;
  readonly dialogRef = inject(MatDialogRef<AddDocumentDialogComponent>);

  eventUpdateForm(value: FormGroup) {
    this.currentForm = value;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  validate() {
      this.dialogRef.close({widget: this.currentForm?.getRawValue()});
    }

}
