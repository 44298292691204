import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FoldersStore } from '@store/folders.store';
import { Element } from '@api';
import { MatIcon } from '@angular/material/icon';
import { NgClass, UpperCasePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipTruncatedDirective } from '@app/directives/tooltip-truncated.directive';

@Component({
  selector: 'app-folder-card',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltipModule,
    NgClass,
    UpperCasePipe,
    TooltipTruncatedDirective,
  ],
  templateUrl: './folder-card.component.html',
  styleUrl: './folder-card.component.scss',
})
export class FolderCardComponent {
  private clickTimeout: any;
  store = inject(FoldersStore);
  @Output() selectElement = new EventEmitter<Element>();
  @Input() selectedElement?: Element;

  select(element: Element) {
    this.selectedElement = element;
    this.selectElement.emit(element);
  }

  open(folder: Element) {
    if (folder.id) {
      this.selectElement.emit(undefined);
      this.store.selectFolder(folder);
    }
  }

  nextPage() {
    this.store.nextPage();
  }
  previousPage() {
    this.store.previousPage();
  }

  view(element: Element) {
    this.store.viewDocument(element);
  }

  handleClick(element: any) {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }

    this.clickTimeout = setTimeout(() => {
      this.select(element);
    }, 200);
  }

  handleDoubleClick(element: any) {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }

    if (element?.objectType === 'folder') {
      this.open(element);
    } else {
      this.view(element);
    }
  }
}
