import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Contract } from '@api';
import { ContractsStore } from '@store/contracts.store';

@Component({
  selector: 'app-contract-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatChip,
    MatChipSet,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    ReactiveFormsModule,
  ],
  providers: [ContractsStore],
  templateUrl: './contract-autocomplete.component.html',
  styleUrl: './contract-autocomplete.component.scss',
})
export class ContractAutocompleteComponent implements OnInit, OnChanges {
  contractStore = inject(ContractsStore);
  @Input() contractsControl!: any;
  @Input() disabled: boolean = false;
  @Input() all: boolean = false;
  contractList: Contract[] = [];
  myControl = new FormControl();

  constructor() {
    this.myControl.valueChanges.subscribe((value: string | null) =>
      this.contractStore.setFilter(value || '')
    );
  }

  ngOnInit() {
    if (!this.all) {
      this.contractList = this.contractsControl.value
        ? this.contractsControl.value
        : [];
    }

    this.contractsControl.valueChanges.subscribe((value: Array<Contract>) => {
      this.contractList = value;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (this.disabled) {
        this.myControl.disable();
      } else {
        this.myControl.enable();
      }
    }
  }

  selectContract(event: any) {
    if (!this.contractList.find((c) => c.id === event.option.value.id)) {
      this.contractList.push(event.option.value);
      this.contractsControl?.setValue(this.contractList);
    }
    this.myControl.setValue(null);
  }

  deleteContract(i: number) {
    this.contractList.splice(i, 1);
    this.contractsControl?.setValue(this.contractList);
  }
}
