<div class="font-bold text-2xl mb-5 ml-3 w-fit">
  Utilisateurs
  <hr class="ml-8 border-2 border-tertiary pr-32" />
</div>
<div class="flex flex-row gap-5">
  <button
    class="rounded-3xl bg-secondary text-white font-semibold py-2 px-8"
    (click)="createUser()"
  >
    Créer un utilisateur
  </button>
  <button
    class="rounded-3xl bg-secondary text-white font-semibold py-2 px-8 flex flex-row items-center gap-2"
    (click)="synchronise()"
  >
    Synchroniser les utilisateurs @if (store.syncUsers()) {
    <mat-spinner class="!w-4 !h-4"></mat-spinner>
    }
  </button>
</div>

<div class="mx-16 my-5 !min-w-fit">
  @if (!store.loadingUsers() && store.total() === 0) { Aucun utilisateur } @else
  {
  <table
    mat-table
    matSort
    [dataSource]="store.users()"
    (matSortChange)="sortData()"
  >
    <ng-container matColumnDef="firstName">
      <th
        mat-header-cell
        mat-sort-header="firstName"
        *matHeaderCellDef
        class="rounded-tl-xl"
      >
        Prénom
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell mat-sort-header="lastName" *matHeaderCellDef>Nom</th>
      <td mat-cell *matCellDef="let element">{{ element?.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header="email" *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{ element?.email }}</td>
    </ng-container>

    <ng-container matColumnDef="button">
      <th mat-header-cell *matHeaderCellDef class="rounded-tr-xl">Action</th>
      <td mat-cell *matCellDef="let element">
        <app-action-button-bar
          [element]="element"
          (editEvent)="updateUser($event)"
          (deleteEvent)="deleteUser($event)"
        ></app-action-button-bar>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    @if (store.loadingUsers()) {
    <div
      role="status"
      class="my-5 mx-16 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
    >
      <div class="flex items-center justify-between">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
    </div>
    } @else {
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    }
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 50]"
    (page)="handlePageEvent()"
    [pageIndex]="store.page() - 1"
    [pageSize]="store.pageSize()"
    [length]="store.total()"
    class="rounded-b-xl"
    showFirstLastButtons
  >
  </mat-paginator>
  }
</div>
