<form [formGroup]="form">
  <ng-template matStepLabel>Attributs</ng-template>

  <div class="m-5 flex flex-row items-center justify-evenly">
    <mat-form-field class="basis-1/3">
      <mat-label>Nom du dossier</mat-label>
      <input matInput type="text" formControlName="name" />
      @if(form.get('name')?.hasError('required')){
      <mat-error>Ce champs est obligatoire</mat-error>
      }
    </mat-form-field>
    <div
      class="relative justify-between w-52 border-2 rounded-2xl bg-secondary/30 p-4 text-center ring-1 ring-inset hover:border-secondary hover:border-2 lg:flex lg:flex-col"
    >
      <p class="text-gray-800 h-12 break-words line-clamp-2">
        {{ form.get("name")?.value | uppercase }}
      </p>
      @if (image || form.get('uuid')?.value) {
      <span
        class="absolute right-2.5 top-2.5 text-red-600 cursor-pointer font-extrabold"
        (click)="removeFile()"
        >X</span
      >
      <img
        class="max-w-24 mx-auto max-h-16"
        [src]="image ? image : '/api/image/' + folder?.uuid"
      />
      }@else{
      <ngx-file-drop
        accept=".png,.jpeg,.jpg"
        dropZoneLabel="Drop files here"
        (onFileDrop)="dropped($event)"
        dropZoneClassName="dropZoneClassName"
        contentClassName="contentClass"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <button
            type="button"
            (click)="openFileSelector()"
            class="flex flex-col items-center"
          >
            <img src="/icons/add-file.svg" class="max-h-16" />
            <div class="text-black text-md">Ajouter une image</div>
          </button>
        </ng-template>
      </ngx-file-drop>
      }
    </div>
  </div>
</form>
