import {Component, inject} from '@angular/core';
import {LinksStore} from "@store/links.store";

@Component({
  selector: 'app-links',
  standalone: true,
  imports: [],
  templateUrl: './links.component.html',
  styleUrl: './links.component.scss'
})
export class LinksComponent {
  store = inject(LinksStore);
}
