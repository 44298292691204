import {Directive, inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Rule} from "@api";
import {ConstStore} from "@store/const.store";

@Directive({
  selector: '[appRight]',
  standalone: true
})
export class RightDirective {
  private _right!: Rule.TypeEnum[];
  private _rules?: Rule[];
  constStore = inject(ConstStore);

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
  }

  @Input() set appRight(context: { right?: Rule.TypeEnum[], rules?: Rule[] }) {
    this._right = context.right || [];
    this._rules = context.rules;
    // Check if the right exists in the rules list
    if ( this.constStore.user().admin ||
      this._rules?.find((rule) =>
        this._right?.indexOf(rule.type) > -1  &&
      rule.profileId ===this.constStore.user()?.profileId &&
      (rule.agencies.indexOf(this.constStore.contract().agencyId || -1) ||
        rule.activities.indexOf(this.constStore.contract().activityId || -1) ||
        rule.contracts.indexOf(this.constStore.contract() || -1) ||
        rule.all
      ))) {
      // If right exists, display the element
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // If right doesn't exist, clear the view (hide the element)
      this.viewContainer.clear();
    }
  }
}
