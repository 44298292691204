<div class="flex flex-col justify-between">
  <div class="flex flex-row bg-gray-200 py-5 justify-center gap-10 items-center px-20">
    <img [src]="'/api/image/'+news.uuid" class="h-[280px]">
    <div class="flex flex-col gap-2">
      <span class="text-4xl font-bold text-primary">{{ news.name |uppercase }}</span>
      <span class="text-md text-secondary">{{ news.createDate | date : 'mediumDate' }}</span>
    </div>
  </div>
  <div class="py-5 px-24 overflow-scroll max-h-80" [innerHTML]="news.content"></div>
  <div class="flex flex-row gap-1 items-center justify-evenly m-5">
    <button class="rounded-2xl bg-gray-300 text-black px-5 py-2 w-fit hover:bg-gray-400" (click)="cancel()">
      OK
    </button>
  </div>
</div>
