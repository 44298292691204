import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {catchError, from, switchMap, throwError} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {inject} from "@angular/core";
import {KeycloakService} from "keycloak-angular";

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const toastr = inject(ToastrService);
  const keycloak = inject(KeycloakService);

  return from(keycloak.getToken()).pipe(
    switchMap((token: string) => {
      const clonedRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next(clonedRequest);
    }),
    catchError((error: HttpErrorResponse) => {
      switch(error.status){
        case 403:
          if(error.error==='FOLDER_NOT_EMPTY'){
            toastr.error('Vous ne pouvez pas supprimer un dossier qui contient des sous dossiers ou documents.','Attention');
          }else{
            toastr.error(error.message,'Attention');
          }
          break;
        case 401:
            toastr.error(`Vous n'avez pas les droits pour effectuer cette action.`,'Attention');
          break;
        default :
          toastr.error(error.message,'Attention');
          break;
      }
      return throwError(error);
    }));
};
