<div class="flex flex-col gap-3">
  <div class="flex flex-row gap-5 items-center">
    <span class="font-bold">Profils :</span>
    @for (profile of store.profileWithoutAdmin(); track profile) {
      <button
        class="p-1  border-2 border-secondary rounded-md "
        [ngClass]="selectedProfile?.id===profile.id ? 'bg-secondary text-white' : 'bg-white text-secondary'"
        (click)="changeProfile(profile)"
      >
        {{ profile.name }}
      </button>
    }
  </div>

  <div class="flex flex-row gap-5">
    <form [formGroup]="form" class="flex flex-col flex-1 gap-2">
      <div class="flex flex-row items-center">
        <span class="font-semibold">Sélectionnez les critères des droits :</span>
        <mat-checkbox formControlName="all">
          Tous les contrats
        </mat-checkbox>
      </div>

      <div class="flex flex-col w-full -mb-4">
        <mat-form-field class="pt-3">
          <mat-label>Droits</mat-label>
          <mat-select
            class="custom-select"
            formControlName="type" placeholder="Sélectionnez les droits">
            @for (r of rightsType; track r) {
              <mat-option [value]="r">{{ r | right }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <mat-expansion-panel [expanded]="step() === 0" (opened)="step.set(0)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Activités
            @if (form.get('activities')?.value?.length > 0) {
              <div class="relative rounded-2xl bg-secondary ml-3 h-6 w-6 text-center">
                <span class="m-auto text-white text-xs">{{ form.get('activities')?.value?.length }}</span>
              </div>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
        <span>
            <ul>
              @for (activity of store.activities(); track activity; let i = $index) {
                <li>
                  <mat-checkbox (change)="selectActivity($event,activity.id)">
                    {{ activity.name }}
                  </mat-checkbox>
                </li>
              }
            </ul>
          </span>
        </div>
      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="step() === 1" (opened)="step.set(1)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Région
            @if (form.get('agencies')?.value?.length > 0) {
              <div class="relative rounded-2xl bg-secondary ml-3 h-6 w-6 text-center">
                <span class="m-auto text-white text-xs">{{ form.get('agencies')?.value?.length }}</span>
              </div>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field class="w-full">
          <mat-label>Région</mat-label>
          <mat-select
            [value]="selectedRegion"
            placeholder="Sélectionnez la region" (selectionChange)="selectedRegionChange($event)">
            @for (region of store.regions(); track region) {
              <mat-option [value]="region.id">{{ region.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <span>
            <ul>
              @for (agence of store.getAgenciesByRegionId(selectedRegion); track agence) {
                <li>
                  <mat-checkbox (change)="selectAgence($event,agence.id)">
                    {{ agence.name }}
                  </mat-checkbox>
                </li>
              }
            </ul>
          </span>
      </mat-expansion-panel>

      <mat-expansion-panel class="!mb-2" [expanded]="step() === 2" (opened)="step.set(2)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Contrats
            @if (form.get('contracts')?.value?.length > 0) {
              <div class="relative rounded-2xl bg-secondary ml-3 h-6 w-6 text-center">
                <span class="m-auto text-white text-xs">{{ form.get('contracts')?.value?.length }}</span>
              </div>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-contract-autocomplete
          [contractsControl]="form.get('contracts')"
        ></app-contract-autocomplete>
      </mat-expansion-panel>
    </form>

    <button (click)="setRules()"
            class="m-auto h-12 w-12 p-1 bg-secondary border-2 border-secondary rounded-2xl text-white disabled:bg-secondary/40 disabled:border-secondary/40 cursor-pointer"
            [disabled]="!form.valid">
      <img src="/icons/arrow_right.svg" class="m-auto"/>
    </button>
    <div class="flex flex-col flex-1 bg-white shadow-sm rounded-md border-2 p-5">
      <app-right-table [rules]="currentRules" [profileId]="form.get('profileId')?.value"
                       (ruleDelete)="deleteRule($event)"></app-right-table>
    </div>
  </div>
</div>
