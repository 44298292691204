@if (store.loadingDocument() && store.total() === 0) { Aucun document } @else {
<table
  matSort
  mat-table
  [matSortActive]="store.colSort()"
  [matSortDirection]="store.sortOrder()"
  [dataSource]="store.documentList()"
  (matSortChange)="sortData()"
>
  <!-- Name Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef class="rounded-tl-xl">Type</th>
    <td mat-cell *matCellDef="let element" class="border-b-gray-400">
      <mat-icon [fontIcon]="element.objectType | objectType"></mat-icon>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header="name" *matHeaderCellDef>Nom</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="createDate">
    <th mat-header-cell mat-sort-header="createDate" *matHeaderCellDef>
      Date d'ajout
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.createDate | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="button">
    <th mat-header-cell *matHeaderCellDef class="rounded-tr-xl">Action</th>
    <td mat-cell *matCellDef="let element">
      <app-action-button-bar
        [element]="element"
        (viewEvent)="view($event)"
        (downloadEvent)="download($event)"
        (editEvent)="edit($event)"
        (deleteEvent)="delete($event)"
      ></app-action-button-bar>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  @if (store.loadingDocument()) {
  <div
    role="status"
    class="my-5 mx-16 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
  >
    <div class="flex items-center justify-between">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
  </div>
  } @else {
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="handleClick(row)"
    (dblclick)="handleDoubleClick(row)"
    [ngClass]="row.id === selectedElement?.id ? '!bg-secondary/20' : ''"
  ></tr>
  }
</table>
<mat-paginator
  [pageSizeOptions]="[10, 20, 50]"
  (page)="handlePageEvent()"
  [pageIndex]="store.page() - 1"
  [pageSize]="store.pageSize()"
  [length]="store.total()"
  class="rounded-b-xl"
  showFirstLastButtons
>
</mat-paginator>
}
