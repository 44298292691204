import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectType',
  standalone: true
})
export class ObjectTypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): any {
    switch(value){
      case 'folder':
        return 'folder';
      case 'document':
        return 'description';
    }
  }

}
