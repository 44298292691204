import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { catchError, forkJoin, of, pipe, switchMap, tap } from 'rxjs';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  Contract,
  ContractsService,
  FileService,
  UploadFile200Response,
} from '@api';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export interface ContractStateInterface {
  contracts: Contract[];
  total: number;
  searchFilter: string;
  colSort: string;
  sortOrder: 'asc' | 'desc' | '';
  page: number;
  pageSize: number;
  loadingContracts: boolean;
}

export const ContractsStore = signalStore(
  withState<ContractStateInterface>({
    contracts: [],
    total: 0,
    searchFilter: '',
    colSort: '',
    sortOrder: '',
    page: 1,
    pageSize: 10,
    loadingContracts: false,
  }),
  withMethods(
    (
      store,
      contractsService = inject(ContractsService),
      fileService = inject(FileService),
      toastr = inject(ToastrService)
    ) => ({
      addContract(contract: Contract, wallpaperBlob: Blob, logoBlob: Blob) {
        fileService
          .uploadImage(wallpaperBlob)
          .subscribe((resp: UploadFile200Response) => {
            contract.wallpaper = resp.uuid;
            fileService
              .uploadImage(logoBlob)
              .subscribe((resp: UploadFile200Response) => {
                contract.logo = resp.uuid;
                contractsService
                  .saveContract(contract)
                  .subscribe(
                    (c) =>
                      toastr.success(`Le contrat "${c.name}" a été créé`) &&
                      this.loadContracts()
                  );
              });
          });
      },
      updateContract(contract: Contract, wallpaperBlob: Blob, logoBlob: Blob) {
        const uploadObservables = [];

        if (wallpaperBlob) {
          const wallpaperUpload$ = fileService.uploadImage(wallpaperBlob).pipe(
            tap((resp: UploadFile200Response) => {
              contract.wallpaper = resp.uuid;
            }),
            catchError((error) => {
              toastr.error('Erreur lors du téléchargement du wallpaper.');
              console.error('Erreur upload wallpaper:', error);
              return of(null);
            })
          );
          uploadObservables.push(wallpaperUpload$);
        }

        if (logoBlob) {
          const logoUpload$ = fileService.uploadImage(logoBlob).pipe(
            tap((resp: UploadFile200Response) => {
              contract.logo = resp.uuid;
            }),
            catchError((error) => {
              toastr.error('Erreur lors du téléchargement du logo.');
              console.error('Erreur upload logo:', error);
              return of(null);
            })
          );
          uploadObservables.push(logoUpload$);
        }

        if (uploadObservables.length > 0) {
          forkJoin(uploadObservables)
            .pipe(
              switchMap(() =>
                contractsService.updateContract(contract.id, contract).pipe(
                  catchError((error) => {
                    toastr.error('Erreur lors de la mise à jour du contrat.');
                    console.error('Erreur update contract:', error);
                    throw error;
                  })
                )
              )
            )
            .subscribe((updatedContract: Contract) => {
              toastr.success(
                `Le contrat "${updatedContract.name}" a été mis à jour.`
              );
              this.loadContracts();
            });
        } else {
          contractsService
            .updateContract(contract.id, contract)
            .subscribe((updatedContract: Contract) => {
              toastr.success(
                `Le contrat "${updatedContract.name}" a été mis à jour.`
              );
              this.loadContracts();
            });
        }
      },
      setFilter(value: string) {
        patchState(store, { searchFilter: value });
        this.loadContracts();
      },
      removeContract(contract: Contract) {
        contractsService
          .deleteContract(contract.id)
          .subscribe(
            () =>
              toastr.success(`Le contrat "${contract.name}" a été supprimé`) &&
              this.loadContracts()
          );
      },
      pageEvent(
        colSort: string,
        sortOrder: 'asc' | 'desc' | '',
        page: number,
        pageSize: number
      ) {
        patchState(store, { colSort, sortOrder, page, pageSize });
        this.loadContracts();
      },
      loadContracts: rxMethod<void>(
        pipe(
          switchMap(() => {
            patchState(store, { loadingContracts: true });
            return contractsService
              .getContracts(
                store.colSort(),
                store.sortOrder(),
                store.page(),
                store.pageSize(),
                store.searchFilter()
              )
              .pipe(
                tap((page) => {
                  patchState(store, {
                    // @ts-ignore
                    contracts: page.results,
                    total: page.total,
                    loadingContracts: false,
                  });
                })
              );
          })
        )
      ),
    })
  ),
  withHooks({
    onInit(store) {
      store.loadContracts();
    },
  })
);
