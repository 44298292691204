<div class="shadow-sm rounded-xl border-2 min-w-full h-fit">
  <div class="font-semibold text-lg bg-secondary py-2 px-5 text-white rounded-t-xl relative">
    <span>Détails</span>
    <svg (click)="close()" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
         class="absolute top-3 right-5 cursor-pointer">
      <path
        d="M2.57979 16.9372L2.93334 17.2908L3.28689 16.9372L9.00001 11.2241L14.7131 16.9372L15.0667 17.2908L15.4202 16.9372L16.9369 15.4205L17.2904 15.067L16.9369 14.7134L11.2238 9.00033L16.9369 3.28721L17.2904 2.93366L16.9369 2.58011L15.4202 1.06344L15.0667 0.709885L14.7131 1.06344L9.00001 6.77655L3.28689 1.06344L2.93334 0.709885L2.57979 1.06344L1.06312 2.58011L0.709565 2.93366L1.06312 3.28721L6.77623 9.00033L1.06312 14.7134L0.709565 15.067L1.06312 15.4205L2.57979 16.9372Z"
        fill="#FFFCFC" stroke="#FFFCFC"/>
    </svg>

  </div>
  <div class="flex flex-row cursor-pointer">
    <div (click)="openedPanel = 'ATTRIBUT'" class="flex-1 py-10 text-center font-bold content-center text-xl "
         [ngClass]="openedPanel==='ATTRIBUT' ? 'border-b-2 border-b-secondary bg-secondary/15':'shadow'">
      Attributs
    </div>
    <div *isAdmin="true" (click)="openedPanel = 'PERMISSION'" class="flex-1 py-10 text-center font-bold content-center text-xl"
         [ngClass]="openedPanel==='PERMISSION' ? 'border-b-2 border-b-secondary bg-secondary/15':'shadow'">
      Droits
    </div>
  </div>
  <div class="flex flex-col mx-3 mt-5 gap-3">
    @if (openedPanel === 'ATTRIBUT') {
      <div>
        <div class="pt-5 pb-3">
          <span class="font-semibold">Nom : </span> {{ document?.name }}
        </div>
        <div class=" pt-2 pb-3">
          <span class="font-semibold">Type : </span> Document
        </div>
        <div class=" pt-2 pb-3">
          <span class="font-semibold">Taille : </span>
        </div>
        <div class=" pt-2 pb-3">
          <span class="font-semibold">Date de création : </span> {{ document?.createDate | date:'dd/MM/yyyy' }}
        </div>
        <div class=" pt-2 pb-3">
          <span
            class="font-semibold">Date de dernière modification : </span> {{ document?.updateDate | date:'dd/MM/yyyy' }}
        </div>
      </div>
    } @else {
      <app-right-summary [rules]="document?.rules"></app-right-summary>
    }
  </div>
</div>
