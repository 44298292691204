import { Component, Inject, inject, LOCALE_ID, OnInit } from '@angular/core';
import { NewsStore } from '@store/news.store';
import { DatePipe, formatDate } from '@angular/common';
import { Criteria, News, Rule } from '@api';
import { formatCriteriaString } from '@app/utils/formatUtils';
import { MatDialog } from '@angular/material/dialog';
import { NewsDetailDialogComponent } from '@app/community/news/news-detail-dialog/news-detail-dialog.component';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent {
  protected newsDisplay?: boolean = true;
  store = inject(NewsStore);

  constructor(private dialog: MatDialog, @Inject(LOCALE_ID) locale: string) {
    const date = new Date();
    date.setDate(date.getDate() - 14);

    let criterias: Criteria[] = [
      {
        field: 'updateDate',
        operator: Criteria.OperatorEnum.GreaterThanOrEqualTo,
        value: formatDate(date, "yyyy-MM-dd'T'HH:mm:ss", locale),
      },
      {
        field: 'alwaysDisplay',
        operator: Criteria.OperatorEnum.Equal,
        value: 'true',
      },
    ];
    this.store.pageEvent(
      'updateDate',
      'desc',
      1,
      10,
      formatCriteriaString(criterias)
    );
  }

  open(news: News) {
    const dialogRef = this.dialog.open(NewsDetailDialogComponent, {
      minWidth: '50vw',
      data: news,
    });
  }
}
