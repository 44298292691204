import {Component, inject, Input, OnInit} from '@angular/core';
import {RightPipe} from "@app/pipes/right.pipe";
import {Profile, Rule} from "@api";
import {ConstStore} from "@store/const.store";
import {NgClass} from "@angular/common";
import {AgencePipe} from "@app/pipes/agence.pipe";
import {ActivityPipe} from "@app/pipes/activity.pipe";

@Component({
  selector: 'app-right-summary',
  standalone: true,
  imports: [
    RightPipe,
    NgClass,
    AgencePipe,
    ActivityPipe
  ],
  templateUrl: './right-summary.component.html',
  styleUrl: './right-summary.component.scss'
})
export class RightSummaryComponent implements OnInit{
  rightsType = Object.keys(Rule.TypeEnum);
  @Input() rules?:Rule[];
  constStore = inject(ConstStore);
  selectedProfile!:Profile;
  ruleToDisplay?:Rule[];


  ngOnInit(): void {
    this.selectedProfile = this.constStore.profileWithoutAdmin()[0];
    this.ruleToDisplay = this.rules?.filter(r=>r.profileId === this.selectedProfile.id);
  }

  selectProfile(profile:Profile){
    this.selectedProfile = profile;
    this.ruleToDisplay = this.rules?.filter(r=>r.profileId === profile.id);
  }
}
