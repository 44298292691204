import {Component, inject} from '@angular/core';
import {HeaderComponent} from "../header/header.component";
import {NewsTableComponent} from "../../admin/news-table/news-table.component";
import {LinksComponent} from "../links/links.component";
import {FoldersComponent} from "../../shared/folders/folders.component";
import {NewsComponent} from "@app/community/news/news.component";
import {FoldersStore} from "@store/folders.store";
import {NewsStore} from "@store/news.store";
import {LinksStore} from "@store/links.store";
import {ConstStore} from "@store/const.store";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    NewsTableComponent,
    LinksComponent,
    FoldersComponent,
    NewsComponent
  ],
  providers : [
    FoldersStore,
    NewsStore,
    LinksStore
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  constStore = inject(ConstStore);
}
