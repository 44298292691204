import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatDialogContent} from "@angular/material/dialog";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule} from "ngx-file-drop";
import {RightFormComponent} from "@app/shared/right/right-form/right-form.component";
import {DocumentWithBlob} from "@store/folders.store";
import {UpperCasePipe} from "@angular/common";

@Component({
  selector: 'app-create-document-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogContent,
    MatStep,
    MatStepLabel,
    MatStepper,
    NgxFileDropModule,
    ReactiveFormsModule,
    RightFormComponent,
    UpperCasePipe
  ],
  templateUrl: './create-document-dialog.component.html',
  styleUrl: './create-document-dialog.component.scss'
})
export class CreateDocumentDialogComponent implements OnInit {

  @Input() widget!: { documents: DocumentWithBlob [] };
  @Output() updateForm = new EventEmitter();
  fb = inject(FormBuilder);
  form!: FormGroup;
  files: any;
  modifyDoc = false;

  ngOnInit(): void {
    this.modifyDoc = this.widget.documents?.length > 0;
    this.form = this.fb.group({
      documents: new FormControl(this.widget.documents, [Validators.required]),
    });
    this.updateForm.emit(this.form);

    this.form.valueChanges.subscribe((val) => {
      this.updateForm.emit(this.form);
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    // Is it a file?
    files.forEach((file) => {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.widget.documents?.push({id: -1, name: file.name, blob: file});
        });
      }
    });
    this.form.patchValue({documents: this.widget.documents});
  }

  removeFile(index: number) {
    this.widget.documents?.splice(index, 1);
    this.form.patchValue({documents: this.widget.documents});
  }

}
