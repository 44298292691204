<div
  (click)="folderDisplay = !folderDisplay"
  class="flex flex-row mb-5"
  *isAdmin="false"
>
  <img
    [src]="
      '/icons/' + (folderDisplay ? 'arrow_bottom' : 'arrow_right') + '.svg'
    "
    class="ml-5"
  />
  <div class="text-2xl text-primary font-bold ml-5">Les documents</div>
</div>
<div class="font-bold text-2xl mb-5 ml-3 w-fit" *isAdmin="true">
  Documents
  <hr class="ml-8 border-2 border-tertiary pr-20" />
</div>
@if (folderDisplay) {
<app-breadcrumb></app-breadcrumb>
<div class="flex max-sm:flex-col gap-4 min-h-52">
  <div
    class="flex flex-col h-fit"
    [ngClass]="selectedElement ? 'basis-2/3' : 'w-full'"
  >
    <div class="flex flex-row justify-between mb-3">
      <div>
        <ng-content></ng-content>
        @if (!constStore.user().admin && store.currentFolder().id !== -1) {
        <button
          *appRight="{
            right: [Rule.TypeEnum.UPLOAD],
            rules: store.currentFolder().rules
          }"
          (click)="addDocuments()"
          class="rounded-3xl bg-secondary text-white font-semibold py-2 px-8"
        >
          Ajouter des documents
        </button>
        }
      </div>
      @if (store.total() !== 0) {
      <div>
        <button
          (click)="listView = false"
          class="rounded-l-md px-2 pt-2"
          [ngClass]="listView ? 'bg-gray-200' : 'bg-secondary text-white'"
        >
          <mat-icon fontIcon="grid_view"></mat-icon>
        </button>
        <button
          (click)="listView = true"
          class="rounded-r-md px-2 pt-2"
          [ngClass]="!listView ? 'bg-gray-200' : 'bg-secondary text-white'"
        >
          <mat-icon fontIcon="list"></mat-icon>
        </button>
      </div>
      }
    </div>
    @if (store.total() === 0) { Aucun document } @else { @if (listView) {
    <app-tree
      [selectedElement]="selectedElement"
      (selectElement)="select($event)"
    ></app-tree>
    } @else {
    <app-folder-card
      class="flex flex-wrap gap-5"
      [selectedElement]="selectedElement"
      (selectElement)="select($event)"
    ></app-folder-card>
    } }
  </div>
  @if (selectedElement) {
  <app-folder-detail
    [folder]="selectedElement"
    class="flex basis-1/3"
    (closeDetail)="closeDetail()"
  ></app-folder-detail>

  }
</div>
}
