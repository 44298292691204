import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {NgxFileDropModule} from "ngx-file-drop";
import {RightFormComponent} from "@app/shared/right/right-form/right-form.component";
import {DocumentWithBlob} from "@store/folders.store";
interface DialogData {
  content:string;
}
@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatStep,
    MatStepLabel,
    MatStepper,
    NgxFileDropModule,
    RightFormComponent
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {

  readonly dialogRef = inject(MatDialogRef<ConfirmDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);

  cancel(): void {
    this.dialogRef.close();
  }

  validate() {
      this.dialogRef.close({confirm : true});
  }
}
