<ol class="flex items-center whitespace-nowrap mb-5">
  @for (node of store.tree();track node; let last = $last){
    <li class="inline-flex items-center">
      <a class="cursor-pointer flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 font-bold" (click)="select(node)" [ngClass]="last ? 'text-secondary':'text-gray-500'">
        {{ node.name }}
        @if(!last){
          <svg class="shrink-0 mx-2 size-4 text-gray-400 dark:text-neutral-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="m9 18 6-6-6-6"></path>
          </svg>
        }
      </a>
    </li>
  }
</ol>
