/**
 * portal_api API
 * portal_api API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLog { 
    id?: number;
    createDate?: string;
    updateDate?: string;
    createUser?: string;
    updateUser?: string;
    actionType?: ActivityLog.ActionTypeEnum;
    actionObjectType?: ActivityLog.ActionObjectTypeEnum;
    widgetId?: number;
}
export namespace ActivityLog {
    export type ActionTypeEnum = 'INSERT' | 'UPDATE' | 'CONSULTATION' | 'DOWNLOAD';
    export const ActionTypeEnum = {
        INSERT: 'INSERT' as ActionTypeEnum,
        UPDATE: 'UPDATE' as ActionTypeEnum,
        CONSULTATION: 'CONSULTATION' as ActionTypeEnum,
        DOWNLOAD: 'DOWNLOAD' as ActionTypeEnum
    };
    export type ActionObjectTypeEnum = 'folder' | 'document' | 'link' | 'news';
    export const ActionObjectTypeEnum = {
        folder: 'folder' as ActionObjectTypeEnum,
        document: 'document' as ActionObjectTypeEnum,
        link: 'link' as ActionObjectTypeEnum,
        news: 'news' as ActionObjectTypeEnum
    };
}


