import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {Element, Profile, Rule} from "@api";
import {RightPipe} from "@app/pipes/right.pipe";
import {ConstStore} from "@store/const.store";
import {IsAdminDirective} from "@app/directives/is-admin.directive";
import {RightSummaryComponent} from "@app/shared/right/right-summary/right-summary.component";

@Component({
  selector: 'app-folder-detail',
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    RightPipe,
    IsAdminDirective,
    RightSummaryComponent
  ],
  templateUrl: './folder-detail.component.html',
  styleUrl: './folder-detail.component.scss'
})
export class FolderDetailComponent {
  @Input() folder?: Element;
  @Output() closeDetail: EventEmitter<boolean> = new EventEmitter<boolean>();
  openedPanel: 'ATTRIBUT' | 'PERMISSION' = 'ATTRIBUT';

  close() {
    this.closeDetail.emit(true);
  }

}
