/**
 * portal_api API
 * portal_api API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Contract } from './contract';


export interface Rule { 
    activities: Array<number>;
    agencies: Array<number>;
    contracts: Array<Contract>;
    profileId?: number;
    type: Rule.TypeEnum;
    all?: boolean;
}
export namespace Rule {
    export type TypeEnum = 'VISIBILITY' | 'CONSULTATION' | 'DOWNLOAD' | 'UPLOAD';
    export const TypeEnum = {
        VISIBILITY: 'VISIBILITY' as TypeEnum,
        CONSULTATION: 'CONSULTATION' as TypeEnum,
        DOWNLOAD: 'DOWNLOAD' as TypeEnum,
        UPLOAD: 'UPLOAD' as TypeEnum
    };
}


