<div class="flex flex-col justify-between">

  <div class="flex flex-col gap-1 items-center text-2xl m-5">
    Créer
    @switch (widgetType()) {
      @case (WidgetType.FOLDER) {
        un Dossier
      }
      @case (WidgetType.DOCUMENT) {
        un Document
      }
      @case (WidgetType.LINK) {
        un Lien
      }
      @case (WidgetType.NEWS) {
        une Actualité
      }
    }
  </div>
  <mat-dialog-content>

    <mat-stepper linear #stepper>
      <mat-step>
        <ng-template matStepLabel>Attributs</ng-template>
        <div class="overflow-auto max-h-screen/2 bg-white shadow-sm rounded-md border-2 p-5">
          @switch (widgetType()) {
            @case (WidgetType.FOLDER) {
              <app-create-folder-dialog [folder]="widget()"
                                        (updateForm)="eventUpdateForm($event)"></app-create-folder-dialog>
            }
            @case (WidgetType.DOCUMENT) {
              <app-create-document-dialog [widget]="widget()"
                                          (updateForm)="eventUpdateForm($event)"></app-create-document-dialog>
            }
            @case (WidgetType.LINK) {
              <app-create-link-dialog [link]="widget()" (updateForm)="eventUpdateForm($event)"></app-create-link-dialog>
            }
            @case (WidgetType.NEWS) {
              <app-create-news-dialog [news]="widget()" (updateForm)="eventUpdateForm($event)"></app-create-news-dialog>
            }
          }

        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Droits</ng-template>
        <div class="overflow-auto max-h-[50vh]">
            <app-right-form (addRule)="addRuleEvent($event)" [currentRules]="rules()"
                            [rightsType]="rightsType()"></app-right-form>
        </div>
      </mat-step>
    </mat-stepper>
  </mat-dialog-content>
  <div class="flex flex-row gap-1 items-center justify-evenly m-5">
    <button class="rounded-2xl bg-gray-300 text-black px-5 py-2 w-fit hover:bg-gray-400" (click)="cancel()">Annuler
    </button>
    <button class="rounded-2xl bg-secondary text-white disabled:bg-secondary/40 px-5 py-2 w-fit"
            [disabled]="!currentForm?.valid" (click)="validate()">
      @if (stepper.selectedIndex === 0) {
        Suivant
      } @else {
        Valider
      }
    </button>
  </div>

</div>
