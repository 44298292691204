import { NgClass } from '@angular/common';
import { Component, inject, model, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Profile, User } from '@api';
import { ContractAutocompleteComponent } from '@app/admin/contract-autocomplete/contract-autocomplete.component';
import { ConstStore } from '@store/const.store';
import { NgxFileDropModule } from 'ngx-file-drop';

interface DialogData {
  user?: User;
}

@Component({
  selector: 'app-create-user-dialog',
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule,
    MatDialogContent,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgxFileDropModule,
    ReactiveFormsModule,
    ContractAutocompleteComponent,
    NgClass,
  ],
  templateUrl: './create-user-dialog.component.html',
  styleUrl: './create-user-dialog.component.scss',
})
export class CreateUserDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<CreateUserDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  user = model(this.data?.user);
  form!: FormGroup;
  selectedProfile?: Profile;
  fb = inject(FormBuilder);
  constStore = inject(ConstStore);

  ngOnInit(): void {
    this.selectedProfile = this.constStore
      .profiles()
      .find((profile) => profile.ref === this.user()?.profileRef);
    this.form = this.fb.group({
      id: new FormControl(this.user()?.id),
      uuid: new FormControl(this.user()?.uuid),
      profileId: new FormControl(this.selectedProfile?.id),
      profileRef: new FormControl(this.selectedProfile?.ref),
      firstName: [this.user()?.firstName, Validators.required],
      lastName: [this.user()?.lastName, Validators.required],
      email: [this.user()?.email, [Validators.required, Validators.email]],
      contracts: [this.user()?.contracts],
      allContracts: [this.user()?.allContracts],
    });
  }

  changeProfile(profile: Profile) {
    this.selectedProfile = profile;
    this.form.patchValue({ profileId: profile.id, profileRef: profile.ref });
    if (this.selectedProfile.ref === 'portal-admin') {
      this.form.patchValue({ contracts: [] });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  validate() {
    this.dialogRef.close({ user: this.form.getRawValue() });
  }

  selectAll() {
    if (this.form.get('allContracts')?.value) {
      this.form.patchValue({ contracts: [] });
    }
  }
}
