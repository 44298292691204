<div class="flex flex-col justify-between">

  <div class="flex flex-col gap-1 items-center text-2xl m-5">
    Ajouter des documents
  </div>
  <mat-dialog-content>
    <div class="overflow-auto max-h-screen/2 bg-white shadow-sm rounded-md border-2 p-5">
      <app-create-document-dialog [widget]="{documents:[]}"
                                  (updateForm)="eventUpdateForm($event)"></app-create-document-dialog>
    </div>
  </mat-dialog-content>
  <div class="flex flex-row gap-1 items-center justify-evenly m-5">
    <button class="rounded-2xl bg-gray-300 text-black px-5 py-2 w-fit hover:bg-gray-400" (click)="cancel()">Annuler
    </button>
    <button class="rounded-2xl bg-secondary text-white disabled:bg-secondary/40 px-5 py-2 w-fit"
            [disabled]="!currentForm?.valid" (click)="validate()">
      Valider
    </button>
  </div>

</div>
