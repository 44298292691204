import {
  Component,
  EventEmitter,
  inject,
  Input,
  model,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { Element } from '@api';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatStep, MatStepLabel, MatStepper } from '@angular/material/stepper';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { RightFormComponent } from '@app/shared/right/right-form/right-form.component';
import { NgClass, UpperCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatInput } from '@angular/material/input';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-create-folder-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatDialogActions,
    FormsModule,
    MatDialogClose,
    ReactiveFormsModule,
    MatDialogTitle,
    MatStep,
    MatStepper,
    MatStepLabel,
    NgxFileDropModule,
    RightFormComponent,
    NgClass,
    UpperCasePipe,
    MatInput,
    MatError,
  ],
  templateUrl: './create-folder-dialog.component.html',
  styleUrl: './create-folder-dialog.component.scss',
})
export class CreateFolderDialogComponent implements OnInit {
  fb = inject(FormBuilder);
  @Input() folder?: Element;
  @Output() updateForm = new EventEmitter();
  form!: FormGroup;
  image?: any;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.folder?.uuid) {
      this.http.get('/icons/folder.svg', { responseType: 'blob' }).subscribe({
        next: (blob: any) => {
          const objectURL = URL.createObjectURL(blob);
          this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        },
      });
    }
    this.form = this.fb.group({
      id: new FormControl(this.folder?.id),
      name: new FormControl(this.folder?.name, [Validators.required]),
      uuid: new FormControl(this.folder?.uuid),
      blob: new FormControl(),
    });
    this.updateForm.emit(this.form);
    this.form.valueChanges.subscribe((val) => {
      this.folder = this.form.getRawValue();
      this.updateForm.emit(this.form);
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    // Is it a file?
    if (
      files[0].fileEntry.isFile &&
      files[0].fileEntry.name.match(`^.*\\.(jpg|jpeg|png)$`)
    ) {
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        let objectURL = URL.createObjectURL(file);
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.form.patchValue({ uuid: file.name, blob: file });
      });
    }
  }

  removeFile() {
    this.image = undefined;
    this.form.patchValue({ uuid: undefined, blob: undefined });
  }
}
