<main class="flex flex-col h-screen">
  @if (!constStore.loadUser()) {
    <app-header-admin class="fixed z-20 w-full" *isAdmin="true"></app-header-admin>
    <app-header class="fixed z-20 w-full" *isAdmin="false"></app-header>
    <div class="flex-1 pt-16">
      <router-outlet/>
    </div>
  }
  <footer class="bg-primary h-16 flex-shrink-0 relative">
    <img src="/icons/aqualter.svg" class="absolute right-6 top-4"/>
  </footer>
</main>
