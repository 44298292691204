import { Component, inject, ViewChild } from '@angular/core';
import { Link, Rule } from '@api';
import { MatDialog } from '@angular/material/dialog';
import { LinksStore } from '@store/links.store';
import { DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import {
  CreateWidgetDialogComponent,
  WidgetType,
} from '@app/shared/create-widget-dialog/create-widget-dialog.component';
import { ActionButtonBarComponent } from '@app/admin/action-button-bar/action-button-bar.component';

@Component({
  selector: 'app-links-table',
  standalone: true,
  imports: [
    DatePipe,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIcon,
    ActionButtonBarComponent,
  ],
  providers: [LinksStore],
  templateUrl: './links-table.component.html',
  styleUrl: './links-table.component.scss',
  host: { class: 'p-5 block' },
})
export class LinksTableComponent {
  store = inject(LinksStore);
  displayedColumns: string[] = ['name', 'url', 'button'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private dialog: MatDialog) {}

  select(row: Link) {}

  addLink() {
    const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
      minWidth: '70vw',
      data: {
        widget: {},
        type: WidgetType.LINK,
        rightsType: [Rule.TypeEnum.VISIBILITY],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.addLink(result.widget, result.widget.blob);
      }
    });
  }

  updateLink(link: Link) {
    const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
      minWidth: '70vw',
      data: {
        widget: link,
        rules: link.rules,
        type: WidgetType.LINK,
        rightsType: [Rule.TypeEnum.VISIBILITY],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.updateLink(result.widget, result.widget.blob);
      }
    });
  }

  deleteLink(element: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '70vw',

      data: { content: `Voulez vous supprimer le lien "${element.name}"?` },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.confirm) {
        this.store.removeLink(element);
      }
    });
  }

  sortData() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  handlePageEvent() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }
}
