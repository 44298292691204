import {APP_INITIALIZER, ApplicationConfig, LOCALE_ID, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {KeycloakService} from "keycloak-angular";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {BASE_PATH} from "../api";
import {environment} from "../environments/environment";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {httpInterceptor} from "@app/interceptor/http.interceptor";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideToastr} from "ngx-toastr";
import {ConstStore} from "@store/const.store";
import '@angular/localize/init';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {FrenchPaginatorIntl} from "@app/french-paginator-intl";
registerLocaleData(localeFr);

export function kcFactory(keycloak: KeycloakService) {
  return () => !keycloak.isLoggedIn() ? keycloak.init({
    config: {
      url: environment.keycloak.url,
      realm:  environment.keycloak.realm,
      clientId:  environment.keycloak.clientId
    },
    initOptions: {
      onLoad: 'login-required',
    }
  }) : true;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideAnimations(),
    provideAnimationsAsync(),
    provideToastr({
      closeButton: true,
    }),
    provideHttpClient(
      withInterceptors([httpInterceptor])
    ),
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      deps: [KeycloakService],
      useFactory: kcFactory,
      multi: true
    },
    {provide: BASE_PATH, useValue: environment.basePath},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: MatPaginatorIntl, useClass: FrenchPaginatorIntl},
    //Stores
    ConstStore
  ]
};
