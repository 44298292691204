import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {MatDialogContent} from "@angular/material/dialog";
import {NgxFileDropEntry, NgxFileDropModule} from "ngx-file-drop";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Link} from "@api";
import {DomSanitizer} from "@angular/platform-browser";
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatLabel} from "@angular/material/select";

@Component({
  selector: 'app-create-link-dialog',
  standalone: true,
  imports: [
    CKEditorModule,
    MatDialogContent,
    NgxFileDropModule,
    ReactiveFormsModule,
    MatLabel,
    MatError,
    MatFormField,
    MatInput
  ],
  templateUrl: './create-link-dialog.component.html',
  styleUrl: './create-link-dialog.component.scss'
})
export class CreateLinkDialogComponent implements OnInit {


  readonly fb = inject(FormBuilder);
  @Input() link?: Link;
  @Output() updateForm = new EventEmitter();
  form!: FormGroup;
  image?: any;

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: new FormControl(this.link?.id),
      name: new FormControl(this.link?.name, [Validators.required]),
      uuid: new FormControl(this.link?.uuid, [Validators.required]),
      url: new FormControl(this.link?.url, [Validators.required, Validators.pattern(/^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(:[0-9]{1,5})?(\/.*)?$/)]),
      blob: new FormControl(),
    });
    this.updateForm.emit(this.form);
    this.form.valueChanges.subscribe((val) => {
      this.updateForm.emit(this.form);
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    // Is it a file?
    if (files[0].fileEntry.isFile && files[0].fileEntry.name.match(`^.*\\.(jpg|jpeg|png)$`)) {
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        let objectURL = URL.createObjectURL(file);
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.form.patchValue({uuid: file.name, blob: file});
      });
    }
  }

  removeFile() {
    this.form.patchValue({uuid: undefined, blob: undefined});
  }
}
