/**
 * portal_api API
 * portal_api API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Criteria { 
    field?: string;
    operator?: Criteria.OperatorEnum;
    value?: string;
}
export namespace Criteria {
    export type OperatorEnum = '>' | '<' | '>=' | '<=' | '=' | '!=';
    export const OperatorEnum = {
        GreaterThan: '>' as OperatorEnum,
        LessThan: '<' as OperatorEnum,
        GreaterThanOrEqualTo: '>=' as OperatorEnum,
        LessThanOrEqualTo: '<=' as OperatorEnum,
        Equal: '=' as OperatorEnum,
        NotEqual: '!=' as OperatorEnum
    };
}


