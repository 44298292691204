import { DatePipe } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { News, Rule } from '@api';
import { ActionButtonBarComponent } from '@app/admin/action-button-bar/action-button-bar.component';
import { ActivityPipe } from '@app/pipes/activity.pipe';
import { AgencePipe } from '@app/pipes/agence.pipe';
import { EntityPipe } from '@app/pipes/entity.pipe';
import { RegionPipe } from '@app/pipes/region.pipe';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import {
  CreateWidgetDialogComponent,
  WidgetType,
} from '@app/shared/create-widget-dialog/create-widget-dialog.component';
import { NewsStore } from '@store/news.store';

@Component({
  selector: 'app-news-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    ActivityPipe,
    AgencePipe,
    EntityPipe,
    RegionPipe,
    MatIcon,
    DatePipe,
    MatSort,
    MatSortHeader,
    ActionButtonBarComponent,
  ],
  providers: [NewsStore],
  templateUrl: './news-table.component.html',
  styleUrl: './news-table.component.scss',
  host: { class: 'p-5 block' },
})
export class NewsTableComponent {
  store = inject(NewsStore);
  displayedColumns: string[] = ['name', 'createDate', 'button'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private dialog: MatDialog) {}

  select(row: News) {}

  addNews() {
    const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
      minWidth: '70vw',
      data: {
        widget: {},
        type: WidgetType.NEWS,
        rightsType: [Rule.TypeEnum.VISIBILITY],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.addNews(result.widget, result.widget.blob);
      }
    });
  }

  updateNews(news: News) {
    const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
      minWidth: '70vw',
      data: {
        widget: news,
        rules: news.rules,
        type: WidgetType.NEWS,
        rightsType: [Rule.TypeEnum.VISIBILITY],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.updateNews(result.widget, result.widget.blob);
      }
    });
  }

  sortData() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  handlePageEvent() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  deleteNews(element: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '70vw',

      data: { content: `Voulez vous supprimer l'actualité "${element.name}"?` },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.confirm) {
        this.store.removeNew(element);
      }
    });
  }
}
