<mat-form-field class="w-full">
  <mat-label>Contrats</mat-label>
  <input
    type="text"
    matInput
    [formControl]="myControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selectContract($event)"
  >
    @for (contract of contractStore.contracts(); track contract) {
    <mat-option [value]="contract">{{
      contract.code + " - " + contract.name
    }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>

<mat-chip-set>
  @for (contract of contractList; track contract; let i = $index) {
  <mat-chip>
    {{ contract.code + " - " + contract.name }}
    <button (click)="deleteContract(i)">
      <img src="/icons/delete.svg" class="ml-2" />
    </button>
  </mat-chip>
  }
</mat-chip-set>
