<div class="font-bold text-2xl mb-5 ml-3 w-fit">
  Contrats
  <hr class="ml-8 border-2 border-tertiary pr-20" />
</div>
<div class="flex flex-col gap-2">
  <button
    class="rounded-3xl bg-secondary text-white font-semibold py-2 px-8 w-fit"
    (click)="createContract()"
  >
    Créer un contrat
  </button>
  <mat-form-field class="mt-3 w-fit">
    <mat-label>
      <mat-icon class="pt-1" fontIcon="search"></mat-icon>&nbsp;Chercher un
      contrat
    </mat-label>
    <input matInput type="search" [formControl]="myControl" />
  </mat-form-field>
</div>
<div class="mx-16 my-5 !min-w-fit">
  @if (!store.loadingContracts() && store.total() === 0) { Aucun contrat } @else
  {
  <table
    mat-table
    matSort
    [dataSource]="store.contracts()"
    (matSortChange)="sortData()"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="code">
      <th
        mat-header-cell
        mat-sort-header="code"
        *matHeaderCellDef
        class="rounded-tl-xl"
      >
        Id
      </th>
      <td mat-cell *matCellDef="let element">{{ element.code }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header="name" *matHeaderCellDef>Nom</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="region">
      <th mat-header-cell mat-sort-header="region" *matHeaderCellDef>Région</th>
      <td mat-cell *matCellDef="let element">
        {{ element.agencyId | region }}
      </td>
    </ng-container>
    <ng-container matColumnDef="agency">
      <th mat-header-cell mat-sort-header="agency" *matHeaderCellDef>Agence</th>
      <td mat-cell *matCellDef="let element">
        {{ element.agencyId | agence }}
      </td>
    </ng-container>
    <ng-container matColumnDef="activity">
      <th mat-header-cell mat-sort-header="activity" *matHeaderCellDef>
        Activité
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.activityId | activity }}
      </td>
    </ng-container>
    <ng-container matColumnDef="entity">
      <th mat-header-cell mat-sort-header="entity" *matHeaderCellDef>
        Entité juridique
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.legalEntityId | entity }}
      </td>
    </ng-container>
    <ng-container matColumnDef="button">
      <th mat-header-cell *matHeaderCellDef class="rounded-tr-xl">Action</th>
      <td mat-cell *matCellDef="let element">
        <button
          (click)="$event.stopPropagation(); update(element)"
          class="m-2 p-2 h-10 shadow rounded-xl bg-white"
        >
          <mat-icon fontIcon="edit"></mat-icon>
        </button>
        <button
          (click)="$event.stopPropagation(); delete(element)"
          class="m-2 p-2 h-10 shadow rounded-xl bg-white"
        >
          <mat-icon fontIcon="close"></mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    @if (store.loadingContracts()) {

    <div
      role="status"
      class="my-5 mx-16 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
    >
      <div class="flex items-center justify-between">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
    </div>

    } @else {
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="select(row)"
    ></tr>
    }
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 50]"
    (page)="handlePageEvent()"
    [pageIndex]="store.page() - 1"
    [pageSize]="store.pageSize()"
    [length]="store.total()"
    class="rounded-b-xl"
    showFirstLastButtons
  >
  </mat-paginator>

  }
</div>
