<form [formGroup]="form">
  <div class="m-5 flex flex-row gap-5 items-center">
    <div class="flex flex-col gap-1 basis-2/3">
      <mat-form-field>
        <mat-label>Sujet de l'actualité</mat-label>
        <input
          matInput
          type="text"
          formControlName="name">
        @if(form.get('name')?.hasError('required')){
          <mat-error>Ce champs est obligatoire</mat-error>
        }
      </mat-form-field>
      <h3 class="font-bold mt-3">Contenu</h3>
      <ckeditor [editor]="Editor" [config]="config" formControlName="content"></ckeditor>
      <mat-checkbox formControlName="alwaysDisplay">
        Toujours visible
      </mat-checkbox>
    </div>
    <div class="shadow w-80 p-5 rounded-xl bg-white">
      <div class="relative">
        @if (form.get('uuid')?.value) {
          <hr class="border-2 border-tertiary rotate-90 z-1 -left-[70px] absolute top-[50%] w-[180px]"/>
          <span class="absolute right-2.5 top-2.5 text-red-600 cursor-pointer font-extrabold"
                (click)="removeFile()">X</span>
          <img  [src]="image ? image : '/api/image/'+news?.uuid" class="h-[200px] rounded-xl">
        } @else {
          <ngx-file-drop accept=".png,.jpeg,.jpg" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                         dropZoneClassName="dropZoneClassName" contentClassName="contentClass">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <button type="button" (click)="openFileSelector()" class="flex flex-col items-center">
                <img src="/icons/add-file.svg" class="h-14"/>
                <div class="text-black text-md">Ajouter une image</div>
              </button>
            </ng-template>
          </ngx-file-drop>
        }
      </div>
      <div class="max-w-[345px]">
        <div class="text-lg text-primary font-bold uppercase flex flex-wrap mt-3">
          {{ form.get('name')?.value }}
        </div>
        <div class="text-xs text-secondary">
          {{currentDate | date }}
        </div>
      </div>
    </div>
  </div>
</form>
