import {inject, Injectable} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {ConstStore} from "@store/const.store";
import {KeycloakProfile} from "keycloak-js";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  username: string | undefined;
  profile?: string;
  constStore = inject(ConstStore);

  constructor(private keycloakService: KeycloakService) {
    keycloakService.loadUserProfile().then((res: KeycloakProfile) => {
      this.username = res.firstName + ' ' + res.lastName;
    });
  }


  isAdmin(): boolean {
    return this.constStore.user()?.admin || false;
  }

  logout() {
    this.keycloakService.logout('https://localhost:4200').then(() => {
    });
  }
}
