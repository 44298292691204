import {inject, Pipe, PipeTransform} from '@angular/core';
import {ConstStore} from "@store/const.store";

@Pipe({
  name: 'region',
  standalone: true
})
export class RegionPipe implements PipeTransform {

  store = inject(ConstStore);

  transform(value: number, ...args: unknown[]): unknown {
    return this.store.getRegionById(value)?.name;
  }

}
