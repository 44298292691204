import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FoldersStore } from '@store/folders.store';
import { DatePipe, NgClass } from '@angular/common';
import { Element, Rule } from '@api';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ObjectTypePipe } from '@app/pipes/object-type.pipe';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import {
  CreateWidgetDialogComponent,
  WidgetType,
} from '@app/shared/create-widget-dialog/create-widget-dialog.component';
import { RightDirective } from '@app/directives/right.directive';
import { ConstStore } from '@store/const.store';
import { ActionButtonBarComponent } from '@app/admin/action-button-bar/action-button-bar.component';

@Component({
  selector: 'app-tree',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    DatePipe,
    MatIcon,
    MatSortHeader,
    MatSort,
    ObjectTypePipe,
    NgClass,
    RightDirective,
    ActionButtonBarComponent,
  ],
  templateUrl: './tree.component.html',
  styleUrl: './tree.component.scss',
})
export class TreeComponent {
  private clickTimeout: any;
  displayedColumns: string[] = ['id', 'name', 'createDate', 'button'];
  store = inject(FoldersStore);
  constStore = inject(ConstStore);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() selectElement: EventEmitter<Element> = new EventEmitter();
  @Input() selectedElement?: Element;

  constructor(private dialog: MatDialog) {}

  select(element: Element) {
    this.selectedElement = element;
    this.selectElement.emit(element);
  }

  open(folder: Element) {
    if (folder.id) {
      this.selectElement.emit(undefined);
      this.store.selectFolder(folder);
    }
  }
  handlePageEvent() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  sortData() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  download(element: Element) {
    this.store.downloadDocument(element);
  }

  view(element: Element) {
    this.store.viewDocument(element);
  }

  handleClick(row: any) {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }

    this.clickTimeout = setTimeout(() => {
      this.select(row);
    }, 200);
  }

  handleDoubleClick(row: any) {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }

    if (row?.objectType === 'folder') {
      this.open(row);
    } else {
      this.view(row);
    }
  }

  edit(element: Element) {
    if (element.objectType === 'folder') {
      const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
        minWidth: '70vw',
        data: {
          widget: element,
          rules: element.rules,
          type: WidgetType.FOLDER,
          rightsType: Object.keys(Rule.TypeEnum).filter(
            (r) => r !== Rule.TypeEnum.DOWNLOAD
          ),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          this.store.updateFolder(result.widget, result.widget.blob);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
        minWidth: '70vw',
        data: {
          widget: { documents: [element] },
          rules: element.rules,
          type: WidgetType.DOCUMENT,
          rightsType: Object.keys(Rule.TypeEnum).filter(
            (r) => r !== Rule.TypeEnum.UPLOAD
          ),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          result?.widget?.documents?.forEach((e: Element) =>
            this.store.updateDocument(e, result?.widget?.rules)
          );
        }
      });
    }
  }

  delete(element: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '70vw',
      data: { content: `Voulez vous supprimer le document "${element.name}"?` },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        if (element.objectType === 'folder') {
          this.store.removeFolder(element);
        } else {
          this.store.removeDocument(element);
        }
      }
    });
  }
}
