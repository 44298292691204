<div class="shadow-md text-center min-w-28 h-full max-sm:border-t">
  <div class="text-md font-bold text-primary py-5">Liens Utiles</div>

  <div class="flex flex-col gap-3">
    @for (link of store.links(); track link) {
    <a [href]="link.url" target="_blank">
      <div
        class="flex flex-col items-center cursor-pointer shadow rounded-lg py-2 mx-5"
      >
        <!--[src]="link.image"-->
        <img
          class="max-w-12 mx-auto max-h-12"
          [src]="'/api/image/' + link.uuid"
        />
        <div class="text-sm mt-2 px-2 font-semibold text-primary">
          {{ link.name }}
        </div>
      </div>
    </a>
    }
  </div>
</div>
