import { Component, inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatTableModule,
} from '@angular/material/table';
import { User } from '@api';
import { ActionButtonBarComponent } from '@app/admin/action-button-bar/action-button-bar.component';
import { CreateUserDialogComponent } from '@app/admin/users/create-user-dialog/create-user-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { UsersStore } from '@store/users.store';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    ActionButtonBarComponent,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatPaginatorModule,
    MatTableModule,
    MatSortHeader,
    MatSort,
    MatProgressSpinnerModule,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
  providers: [UsersStore],
  host: { class: 'p-5 block' },
})
export class UsersComponent {
  store = inject(UsersStore);
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'button'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private dialog: MatDialog) {}

  createUser() {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {
      minWidth: '70vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.addUser(result.user);
      }
    });
  }

  updateUser(user: User) {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {
      minWidth: '70vw',
      data: { user },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.updateUser(result.user);
      }
    });
  }

  sortData() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  handlePageEvent() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  deleteUser(user: User) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '70vw',

      data: {
        content: `Voulez vous supprimer l'utilisateur ${user.firstName} ${user.lastName}?`,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.confirm) {
        this.store.removeUser(user);
      }
    });
  }

  synchronise() {
    this.store.synchronise();
  }
}
