import {Component, inject, OnInit} from '@angular/core';
import {NgClass, SlicePipe, UpperCasePipe} from "@angular/common";
import {GuardsCheckEnd, NavigationStart, Router, RouterModule} from "@angular/router";
import {MENU} from "../../app.routes";
import {UserService} from "@app/services/user/user.service";
import {MatMenu, MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {ConstStore} from "@store/const.store";

@Component({
  selector: 'app-header-admin',
  standalone: true,
  imports: [
    NgClass,
    MatMenu,
    RouterModule,
    MatButtonModule, MatMenuModule, UpperCasePipe, SlicePipe
  ],
  templateUrl: './header-admin.component.html',
  styleUrl: './header-admin.component.scss'
})
export class HeaderAdminComponent implements OnInit {
  protected displayBurgerMenu = false;
  protected page!: MENU;
  protected readonly MENU = MENU;
  constStore = inject(ConstStore);

  constructor(private router: Router, public userService: UserService) {
  }

  ngOnInit() {
    const url = this.router.url.split('/').pop();
    // @ts-ignore
    this.page = MENU[url?.toUpperCase()];
    this.router.events.subscribe((event) =>
      // @ts-ignore
      event instanceof GuardsCheckEnd ? this.page = MENU[event.urlAfterRedirects.split('/').pop()?.toUpperCase()] : null
    )
  }


  logout() {
    this.userService.logout();
  }
}
