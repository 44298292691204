import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from "@app/services/user/user.service";

@Directive({
  selector: '[isAdmin]',
  standalone: true
})
export class IsAdminDirective {

  constructor(private userService: UserService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  @Input() set isAdmin(displayForAdmin:boolean) {
    if (this.userService.isAdmin() !==displayForAdmin) {
      this.viewContainer.clear();
    }else{
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
