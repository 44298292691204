import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { pipe, switchMap, tap } from 'rxjs';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  DocumentsService,
  Element,
  FileService,
  FoldersService,
  Rule,
  UploadFile200Response,
} from '@api';
import { effect, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { ToastrService } from 'ngx-toastr';
import { ConstStore } from '@store/const.store';
import { parse } from 'file-type-mime';
import { saveAs } from 'file-saver';

const WIDGETS = 'WIDGETS';

export interface DocumentWithBlob extends Element {
  blob: File;
}

export interface FolderStateInterface {
  currentFolder: Element;
  tree: Element[];
  documentList: Element[];
  total: number;
  searchFilter: string;
  colSort: string;
  sortOrder: 'asc' | 'desc' | '';
  page: number;
  pageSize: number;
  loadingDocument: boolean;
}

export const FoldersStore = signalStore(
  withState<FolderStateInterface>({
    currentFolder: { name: WIDGETS, id: -1 },
    total: 0,
    tree: [{ name: WIDGETS, id: -1 }],
    documentList: [],
    searchFilter: '',
    colSort: '',
    sortOrder: '',
    page: 1,
    pageSize: 10,
    loadingDocument: false,
  }),
  withMethods(
    (
      store,
      constStore = inject(ConstStore),
      foldersService = inject(FoldersService),
      documentService = inject(DocumentsService),
      fileService = inject(FileService),
      toastr = inject(ToastrService)
    ) => ({
      addFolder(folder: Element, file: Blob) {
        if (store.currentFolder()?.id !== -1) {
          folder.parentId = store.currentFolder()?.id;
        }
        folder.objectType = 'folder';
        if (file) {
          fileService
            .uploadImage(file)
            .subscribe((resp: UploadFile200Response) => {
              folder.uuid = resp.uuid;
              foldersService
                .saveFolder(folder)
                .subscribe(
                  () =>
                    toastr.success(`Le dossier "${folder.name}" a été créé`) &&
                    this.loadFolders()
                );
            });
        } else {
          foldersService.saveFolder(folder).subscribe(() => {
            toastr.success(`Le dossier "${folder.name}" a été créé`);
            this.loadFolders();
          });
        }
      },
      updateFolder(folder: Element, file?: Blob) {
        if (store.currentFolder()?.id !== -1) {
          folder.parentId = store.currentFolder()?.id;
        }
        folder.objectType = 'folder';

        if (file) {
          fileService
            .uploadImage(file)
            .subscribe((resp: UploadFile200Response) => {
              folder.uuid = resp.uuid;
              foldersService.updateFolder(folder.id, folder).subscribe(() => {
                toastr.success(`Le dossier "${folder.name}" a été mis à jour`);
                this.loadFolders();
              });
            });
        } else {
          foldersService.updateFolder(folder.id, folder).subscribe(() => {
            toastr.success(`Le dossier "${folder.name}" a été mis à jour`);
            this.loadFolders();
          });
        }
      },
      selectFolder(folder: Element) {
        let updatedTree =
          store.tree().findIndex((node) => node.id === folder.id) > -1
            ? store
                .tree()
                .splice(
                  0,
                  store.tree().findIndex((node) => node.id === folder.id) + 1
                )
            : [...store.tree(), folder];
        patchState(store, { tree: updatedTree, currentFolder: folder });
        this.loadFolders();
      },
      removeFolder(element: Element) {
        foldersService
          .deleteFolder(element.id)
          .subscribe(
            () =>
              toastr.success(`Le dossier "${element.name}" a été supprimé`) &&
              this.loadFolders()
          );
      },
      addDocument(document: DocumentWithBlob, rules: Rule[]) {
        document.rules = rules;
        document.objectType = 'document';
        document.parentId = store.currentFolder()?.id;
        fileService
          .uploadFile(document.blob)
          .subscribe((resp: UploadFile200Response) => {
            document.uuid = resp.uuid;
            documentService
              .saveDocument(document as Element)
              .subscribe(
                (element: Element) =>
                  toastr.success(
                    `Le document "${element.name}" a été ajouté`
                  ) && this.loadFolders()
              );
          });
      },
      updateDocument(document: Element, rules: Rule[]) {
        document.rules = rules;
        document.objectType = 'document';
        documentService
          .updateDocument(document.id, document as Element)
          .subscribe(
            (element: Element) =>
              toastr.success(`Le document "${element.name}" a été ajouté`) &&
              this.loadFolders()
          );
      },
      viewDocument(document: Element) {
        fileService
          .getFile(document.uuid || '', 'CONSULTATION', document.id)
          .subscribe(async (res) => {
            const result = parse(await res.arrayBuffer());
            const blob = new Blob([res], { type: result?.mime });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          });
      },
      downloadDocument(document: Element) {
        fileService
          .getFile(document.uuid || '', 'DOWNLOAD', document.id)
          .subscribe(async (res) => {
            saveAs(res, document.name);
          });
      },
      removeDocument(element: Element) {
        documentService
          .deleteDocument(element.id)
          .subscribe(
            () =>
              toastr.success(`Le document "${element.name}" a été supprimé`) &&
              this.loadFolders()
          );
      },
      nextPage() {
        patchState(store, { page: store.page() + 1 });
        this.loadFolders();
      },
      previousPage() {
        patchState(store, { page: store.page() - 1 });
        this.loadFolders();
      },
      pageEvent(
        colSort: string,
        sortOrder: 'asc' | 'desc' | '',
        page: number,
        pageSize: number
      ) {
        patchState(store, { colSort, sortOrder, page, pageSize });
        this.loadFolders();
      },
      loadFolders: rxMethod<void>(
        pipe(
          switchMap(() => {
            patchState(store, { loadingDocument: true });
            if (store.currentFolder()?.id === -1) {
              return foldersService
                .getFolders(
                  constStore.contract()?.id,
                  store.colSort(),
                  store.sortOrder(),
                  store.page(),
                  store.pageSize(),
                  store.searchFilter()
                )
                .pipe(
                  tapResponse({
                    next: (page) =>
                      patchState(store, {
                        // @ts-ignore
                        documentList: page.results,
                        loadingDocument: false,
                        total: page.total,
                      }),
                    error: (err: any) => {
                      patchState(store, { loadingDocument: false });
                      console.error(err);
                    },
                  })
                );
            } else {
              const folder: Element = store.currentFolder();
              return foldersService
                .getFolderChildrenById(
                  folder.id,
                  constStore.contract()?.id,
                  store.colSort(),
                  store.sortOrder(),
                  store.page(),
                  store.pageSize(),
                  store.searchFilter()
                )
                .pipe(
                  tap((page) => {
                    patchState(store, {
                      // @ts-ignore
                      documentList: page.results,
                      loadingDocument: false,
                      total: page.total,
                    });
                  })
                );
            }
          })
        )
      ),
    })
  ),
  withHooks({
    onInit(store) {
      store.loadFolders();

      const constStore = inject(ConstStore);
      effect(
        () => {
          const contract = constStore.contract();
          store.loadFolders();
        },
        { allowSignalWrites: true }
      );
    },
  })
);
