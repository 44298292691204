import { DatePipe } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatLabel } from '@angular/material/select';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Contract } from '@api';
import { CreateContractDialogComponent } from '@app/admin/contracts/create-contract-dialog/create-contract-dialog.component';
import { ActivityPipe } from '@app/pipes/activity.pipe';
import { AgencePipe } from '@app/pipes/agence.pipe';
import { EntityPipe } from '@app/pipes/entity.pipe';
import { RegionPipe } from '@app/pipes/region.pipe';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { ContractsStore } from '@store/contracts.store';

@Component({
  selector: 'app-contracts',
  standalone: true,
  imports: [
    DatePipe,
    MatIcon,
    MatTableModule,
    MatPaginatorModule,
    EntityPipe,
    AgencePipe,
    ActivityPipe,
    RegionPipe,
    MatSortHeader,
    MatSort,
    MatFormField,
    MatInput,
    MatLabel,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
  ],
  providers: [ContractsStore],
  templateUrl: './contracts.component.html',
  styleUrl: './contracts.component.scss',
  host: { class: 'p-5 block' },
})
export class ContractsComponent {
  store = inject(ContractsStore);
  displayedColumns: string[] = [
    'code',
    'name',
    'region',
    'agency',
    'activity',
    'entity',
    'button',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  myControl = new FormControl();

  constructor(private dialog: MatDialog) {
    this.myControl.valueChanges.subscribe((value: string | null) =>
      this.store.setFilter(value || '')
    );
  }

  select(row: Contract) {}

  update(contract: Contract) {
    const dialogRef = this.dialog.open(CreateContractDialogComponent, {
      minWidth: '70vw',
      data: { contract },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.updateContract(
          result.contract,
          result.contract.wallpaperBlob,
          result.contract.logoBlob
        );
      }
    });
  }

  sortData() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  handlePageEvent() {
    this.store.pageEvent(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }

  delete(element: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '70vw',

      data: {
        content: `Voulez vous supprimer le contract "${element.name}" ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.confirm) {
        this.store.removeContract(element.id);
      }
    });
  }

  createContract() {
    const dialogRef = this.dialog.open(CreateContractDialogComponent, {
      minWidth: '70vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.store.addContract(
          result.contract,
          result.contract.wallpaperBlob,
          result.contract.logoBlob
        );
      }
    });
  }
}
