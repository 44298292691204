<app-folders>
  <div class="flex flex-row gap-5" *isAdmin="true">
    <button (click)="createFolder()" class="rounded-3xl bg-secondary text-white font-semibold py-2 px-8">
      Créer un widget
    </button>
    @if (store.currentFolder().id !== -1) {
      <button (click)="addDocuments()" class="rounded-3xl bg-secondary text-white font-semibold py-2 px-8">
        Ajouter des documents
      </button>
    }
  </div>
</app-folders>
