@if (element.objectType === 'document') {
<button *appRight="{right: [rightType.CONSULTATION,rightType.DOWNLOAD], rules :element.rules}"
(click)="$event.stopPropagation();view(element)" class="m-2 p-2 h-10 shadow rounded-xl bg-white">
<mat-icon fontIcon="visibility"></mat-icon>
</button>
}
@if (element.objectType === 'document') {
<button *appRight="{right: [rightType.DOWNLOAD], rules :element.rules}"
(click)="$event.stopPropagation();download(element)" class="m-2 p-2 h-10 shadow rounded-xl bg-white">
<mat-icon fontIcon="download"></mat-icon>
</button>
}
@if (constStore.user().admin || element.createUser === constStore.user().uuid) {
<button (click)="$event.stopPropagation();edit(element)" class="m-2 p-2 h-10 shadow rounded-xl bg-white">
<mat-icon fontIcon="edit"></mat-icon>
</button>
<button (click)="$event.stopPropagation();delete(element)" class="m-2 p-2 h-10 shadow rounded-xl bg-white">
<mat-icon fontIcon="close"></mat-icon>
</button>
}
