import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {RightPipe} from "@app/pipes/right.pipe";
import {Element, Rule} from "@api";
import {IsAdminDirective} from "@app/directives/is-admin.directive";
import {RightSummaryComponent} from "@app/shared/right/right-summary/right-summary.component";

@Component({
  selector: 'app-document-detail',
  standalone: true,
  imports: [
    DatePipe,
    RightPipe,
    NgClass,
    IsAdminDirective,
    RightSummaryComponent
  ],
  templateUrl: './document-detail.component.html',
  styleUrl: './document-detail.component.scss'
})
export class DocumentDetailComponent {
  @Input() document?: Element;
  @Output() closeDetail: EventEmitter<boolean> = new EventEmitter<boolean>();
  openedPanel: 'ATTRIBUT' | 'PERMISSION' = 'ATTRIBUT';
  rightsType = Object.keys(Rule.TypeEnum);

  close() {
    this.closeDetail.emit(true);
  }
}
