import {Component, inject, model, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {NgxFileDropModule} from "ngx-file-drop";
import {RightFormComponent} from "@app/shared/right/right-form/right-form.component";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {
  CreateDocumentDialogComponent
} from "@app/shared/create-widget-dialog/create-document-dialog/create-document-dialog.component";
import {
  CreateFolderDialogComponent
} from "@app/shared/create-widget-dialog/create-folder-dialog/create-folder-dialog.component";
import {
  CreateLinkDialogComponent
} from "@app/shared/create-widget-dialog/create-link-dialog/create-link-dialog.component";
import {
  CreateNewsDialogComponent
} from "@app/shared/create-widget-dialog/create-news-dialog/create-news-dialog.component";
import {Rule} from "@api";

export enum WidgetType {
  DOCUMENT,
  FOLDER,
  LINK,
  NEWS
}

interface DialogData {
  widget?: any;
  rules?:Rule[];
  type: WidgetType;
  rightsType: string[];
}

@Component({
  selector: 'app-create-widget-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatDialogActions,
    FormsModule,
    MatDialogClose,
    ReactiveFormsModule,
    MatDialogTitle,
    MatStep,
    MatStepper,
    MatStepLabel,
    NgxFileDropModule,
    RightFormComponent,
    CreateDocumentDialogComponent,
    CreateFolderDialogComponent,
    CreateLinkDialogComponent,
    CreateNewsDialogComponent
  ],
  templateUrl: './create-widget-dialog.component.html',
  styleUrl: './create-widget-dialog.component.scss'
})
export class CreateWidgetDialogComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;
  readonly dialogRef = inject(MatDialogRef<CreateWidgetDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  widget = model(this.data.widget);
  rules = model(this.data.rules);
  widgetType = model(this.data.type);
  rightsType = model(this.data.rightsType || Object.keys(Rule.TypeEnum));
  protected readonly WidgetType = WidgetType;
  currentForm?: FormGroup;

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  eventUpdateForm(value: FormGroup) {
    this.currentForm = value;
    this.widget.set(value.getRawValue());
  }


  addRuleEvent(rules: Rule[]) {
    this.rules.set(rules);
  }

  validate() {
    if (this.stepper.selectedIndex === 0) {
      this.stepper.next();
    } else {
      this.widget.set({...this.widget(), rules: this.rules()});
      this.dialogRef.close({widget: this.widget()});
    }
  }


}
