import { NgClass, SlicePipe, UpperCasePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ActivationStart, Router } from '@angular/router';
import { EntityPipe } from '@app/pipes/entity.pipe';
import { SortPipe } from '@app/pipes/sort.pipe';
import { UserService } from '@app/services/user/user.service';
import { ConstStore } from '@store/const.store';
import { MENU } from '../../app.routes';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgClass,
    MatOption,
    MatSelect,
    MatMenuTrigger,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    UpperCasePipe,
    SlicePipe,
    EntityPipe,
    SortPipe,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  protected readonly MENU = MENU;
  protected page: MENU | undefined;
  constStore = inject(ConstStore);
  contractControl: FormControl = new FormControl();

  constructor(private router: Router, public userService: UserService) {}

  ngOnInit() {
    this.router.events.subscribe((event) =>
      event instanceof ActivationStart
        ? (this.page = event.snapshot.data['menu'])
        : null
    );
    this.contractControl = new FormControl(this.constStore.contract()?.id);
  }

  selectContract(contract: MatSelectChange) {
    const contractId = contract.value;
    const selectedContract = this.constStore
      .user()
      .contracts.find((contract) => contract.id === contractId);
    if (selectedContract) {
      this.constStore.setCurrentContract(selectedContract);
    }
  }

  logout() {
    this.userService.logout();
  }
}
