<form [formGroup]="form">
  <div class="m-5 flex flex-row gap-5">
    <div class="flex flex-col gap-1 basis-2/3">
      <mat-form-field>
        <mat-label>Nom du lien</mat-label>
        <input
          matInput
          type="text"
          formControlName="name">
        @if(form.get('name')?.hasError('required')){
          <mat-error>Ce champs est obligatoire</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>URL</mat-label>
        <input
          type="text"
          matInput
          formControlName="url">
        @if (form.get('url')?.hasError('pattern')) {
          <mat-error>L'url doit être un lien hypertexte</mat-error>
        } @else if(form.get('url')?.hasError('required')){
          <mat-error>Ce champs est obligatoire</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="flex flex-col items-center cursor-pointer shadow rounded-lg py-2 relative h-auto w-40 m-auto">
      @if (form.get('uuid')?.value) {

        <span class="absolute right-2.5 top-2.5 text-red-600 cursor-pointer font-extrabold"
              (click)="removeFile()">X</span>
        <!--[src]="link.image"-->
        <img class="w-32 mx-auto h-auto" [src]="image ? image : '/api/image/'+link?.uuid">
      } @else {
        <ngx-file-drop accept=".png,.jpeg,.jpg" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                       dropZoneClassName="dropZoneClassName" contentClassName="contentClass">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

            <button type="button" (click)="openFileSelector()" class="flex flex-col items-center">
              <img class="max-w-12 mx-auto max-h-12" src="/icons/add-file.svg"/>
              <div class="text-black text-md">Ajouter une image</div>
            </button>
          </ng-template>
        </ngx-file-drop>
      }
      <div class="text-sm font-bold text-primary">{{ form.get('name')?.value }}</div>
    </div>
  </div>
</form>
