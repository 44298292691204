<nav class="bg-primary">
  <div class="px-2 md:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
        <!-- Mobile menu button-->
        <button
          type="button"
          (click)="displayBurgerMenu = !displayBurgerMenu"
          class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only">Open main menu</span>
          <!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg
            class="block h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          <!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg
            class="hidden h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="flex flex-1 h-16 justify-center md:justify-start">
        <div class="flex flex-shrink-0 items-center mr-10">
          <img class="h-8 w-auto" src="/icons/aqualter.svg" alt="Aqualter" />
        </div>
        <div class="hidden md:ml-6 md:flex items-end">
          <div class="flex space-x-6 font-bold">
            <a
              routerLink="/admin/widgets"
              class="rounded-t-lg px-3 py-3 text-sm text-gray-300"
              [ngClass]="
                page === MENU.WIDGETS
                  ? 'bg-white bg-menu bg-svg relative text-primary '
                  : 'hover:bg-gray-700 hover:text-white'
              "
              aria-current="page"
              >Widgets</a
            >
            <a
              routerLink="/admin/news"
              class="rounded-t-lg px-3 py-3 text-sm text-gray-300"
              [ngClass]="
                page === MENU.NEWS
                  ? 'bg-white bg-menu bg-svg relative text-primary '
                  : 'hover:bg-gray-700 hover:text-white'
              "
              >Actualités</a
            >
            <a
              routerLink="/admin/links"
              class="rounded-t-lg px-3 py-3 text-sm text-gray-300"
              [ngClass]="
                page === MENU.LINKS
                  ? 'bg-white bg-menu bg-svg relative text-primary '
                  : 'hover:bg-gray-700 hover:text-white'
              "
              >Liens</a
            >
            <a
              routerLink="/admin/contracts"
              class="rounded-t-lg px-3 py-3 text-sm text-gray-300"
              [ngClass]="
                page === MENU.CONTRACTS
                  ? 'bg-white bg-menu bg-svg relative text-primary '
                  : 'hover:bg-gray-700 hover:text-white'
              "
              >Contrats</a
            >
            <a
              routerLink="/admin/users"
              class="rounded-t-lg px-3 py-3 text-sm text-gray-300"
              [ngClass]="
                page === MENU.USERS
                  ? 'bg-white bg-menu bg-svg relative text-primary '
                  : 'hover:bg-gray-700 hover:text-white'
              "
              >Utilisateurs</a
            >
          </div>
        </div>
      </div>
      <!--<div>
        <form class="max-w-lg mx-auto">
          <div class="flex">
            <div class="relative w-full">
              <input type="search" id="search-dropdown"
                     class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                     placeholder="Recherche" required/>
              <button type="submit"
                      class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
                <span class="sr-only">Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>-->
      <div
        class="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0 pr-5"
      >
        <!--
        <button type="button"
                class="text-white relative rounded-full bg-primary p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary">
          <span class="absolute -inset-1.5"></span>
          <span class="sr-only">View notifications</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
               aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"/>
          </svg>
        </button>
        -->

        <!-- Profile dropdown -->
        <div class="flex flex-row ml-3">
          @if (!constStore.loadUser()) {
          <div
            class="max-lg:hidden flex flex-col text-white text-xs m-2 text-right"
          >
            <span>{{ userService.username }}</span>
            <span>{{ constStore.user().profile | uppercase }}</span>
          </div>
          }
          <div class="flex flex-row justify-center items-center">
            <button
              type="button"
              class="relative flex rounded-full bg-primary text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
              [matMenuTriggerFor]="menu"
            >
              <div
                class="flex items-center justify-center w-8 h-8 rounded-full bg-secondary text-primary text-md font-bold"
              >
                {{ userService.username | slice : 0 : 1 }}
              </div>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="logout()">Déconnexion</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  @if (displayBurgerMenu) {
  <div class="md:hidden" id="mobile-menu">
    <div class="space-y-1 px-2 pb-3 pt-2">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <a
        (click)="displayBurgerMenu = !displayBurgerMenu"
        routerLink="/admin/widgets"
        class="block rounded-md px-3 py-2 text-base font-medium text-gray-300"
        [ngClass]="
          page === MENU.WIDGETS
            ? 'bg-white text-primary '
            : 'hover:bg-gray-700 hover:text-white'
        "
        >Widgets</a
      >
      <a
        (click)="displayBurgerMenu = !displayBurgerMenu"
        routerLink="/admin/news"
        class="block rounded-md px-3 py-2 text-base font-medium text-gray-300"
        [ngClass]="
          page === MENU.NEWS
            ? 'bg-white text-primary '
            : 'hover:bg-gray-700 hover:text-white'
        "
        >Actualités</a
      >
      <a
        (click)="displayBurgerMenu = !displayBurgerMenu"
        routerLink="/admin/links"
        class="block rounded-md px-3 py-2 text-base font-medium text-gray-300"
        [ngClass]="
          page === MENU.LINKS
            ? 'bg-white text-primary '
            : 'hover:bg-gray-700 hover:text-white'
        "
        >Liens</a
      >
      <a
        (click)="displayBurgerMenu = !displayBurgerMenu"
        routerLink="/admin/contracts"
        class="block rounded-md px-3 py-2 text-base font-medium text-gray-300"
        [ngClass]="
          page === MENU.CONTRACTS
            ? 'bg-white text-primary '
            : 'hover:bg-gray-700 hover:text-white'
        "
        >Contrats</a
      >
      <a
        (click)="displayBurgerMenu = !displayBurgerMenu"
        routerLink="/admin/users"
        class="block rounded-md px-3 py-2 text-base font-medium text-gray-300"
        [ngClass]="
          page === MENU.USERS
            ? 'bg-white text-primary '
            : 'hover:bg-gray-700 hover:text-white'
        "
        >Utilisateurs</a
      >
    </div>
  </div>
  }
</nav>
