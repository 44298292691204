import {Component, inject} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {HeaderComponent} from "./community/header/header.component";
import {HeaderAdminComponent} from "./admin/header-admin/header-admin.component";
import {UserService} from "./services/user/user.service";
import {IsAdminDirective} from "@app/directives/is-admin.directive";
import {ConstStore} from "@store/const.store";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, HeaderAdminComponent, IsAdminDirective],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'portal-ui';
  constStore = inject(ConstStore);
  constructor(private router:Router) {
    this.constStore.loadUserContracts().subscribe();
  }

}
