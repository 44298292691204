/**
 * portal_api API
 * portal_api API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Rule } from './rule';


export interface Element { 
    id: number;
    createDate?: string;
    updateDate?: string;
    createUser?: string;
    updateUser?: string;
    name: string;
    parentId?: number;
    uuid?: string;
    rules?: Array<Rule>;
    objectType?: Element.ObjectTypeEnum;
}
export namespace Element {
    export type ObjectTypeEnum = 'folder' | 'document';
    export const ObjectTypeEnum = {
        Folder: 'folder' as ObjectTypeEnum,
        Document: 'document' as ObjectTypeEnum
    };
}


