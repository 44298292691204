import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Rule} from "@api";
import {RightPipe} from "@app/pipes/right.pipe";
import {AgencePipe} from "@app/pipes/agence.pipe";
import {ActivityPipe} from "@app/pipes/activity.pipe";
import {ConstStore} from "@store/const.store";

@Component({
  selector: 'app-right-table',
  standalone: true,
  imports: [
    RightPipe,
    AgencePipe,
    ActivityPipe
  ],
  templateUrl: './right-table.component.html',
  styleUrl: './right-table.component.scss'
})
export class RightTableComponent{
  @Input() rules?:Rule[];
  @Input() profileId?:number;
  rightsType = Object.keys(Rule.TypeEnum);
  @Output() ruleDelete: EventEmitter<number> = new EventEmitter;

  deleteRight(i: number) {
    this.ruleDelete.emit(i);
  }
}
