<div (click)="newsDisplay = !newsDisplay" class="flex flex-row mb-5">
  <img
    [src]="'/icons/' + (newsDisplay ? 'arrow_bottom' : 'arrow_right') + '.svg'"
    class="ml-5"
  />
  <div class="text-2xl text-primary font-bold ml-5">Les actualités ...</div>
</div>
@if (newsDisplay) {
<div class="flex flex-row flex-wrap gap-3">
  @for (news of store.news(); track news) {
  <div class="shadow p-5 rounded-xl" (click)="open(news)">
    <div class="relative">
      <img [src]="'/api/image/' + news.uuid" class="h-[100px] rounded-xl" />
      <hr
        class="border-2 border-tertiary rotate-90 z-1 -left-[32px] absolute top-[50%] w-[80px]"
      />
    </div>

    <div class="max-w-[150px]">
      <div
        class="text-sm text-primary font-semibold uppercase flex flex-wrap mt-3"
      >
        {{ news.name }}
      </div>
      <div class="text-xs mt-2 text-secondary">
        {{ news.updateDate | date }}
      </div>
    </div>
  </div>
  } @empty {
  <div class="mt-5 ml-5">Aucune actualité</div>
  }
</div>
}
