import {inject, Pipe, PipeTransform} from '@angular/core';
import {ConstStore} from "@store/const.store";

@Pipe({
  name: 'entity',
  standalone: true
})
export class EntityPipe implements PipeTransform {

  store = inject(ConstStore);

  transform(value: number, ...args: unknown[]): unknown {
    return this.store.getEntityById(value)?.name;
  }
}
