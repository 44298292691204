import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {ConstStore} from "@store/const.store";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard extends KeycloakAuthGuard {
  constStore = inject(ConstStore);

  constructor(
    router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }
    return new Promise<boolean | UrlTree>((resolve, reject) => {
      this.constStore.loadUserContracts().subscribe((u) => {
        if (u.admin) {
          resolve(true);
        } else {
          resolve(this.router.parseUrl('/home'));
        }
      });
    })
  }
}
