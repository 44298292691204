import {Component, EventEmitter, inject, Input, OnInit, Output, signal} from '@angular/core';
import {MatCheckbox} from "@angular/material/checkbox";
import {ConstStore} from "@store/const.store";
import {MatFormField, MatLabel, MatSelect, MatSelectChange} from "@angular/material/select";
import {RightPipe} from "@app/pipes/right.pipe";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RightTableComponent} from "@app/shared/right/right-table/right-table.component";
import {Activity, Agency, Contract, Profile, Rule} from "@api";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {NgClass} from "@angular/common";
import {MatInput} from "@angular/material/input";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChip, MatChipSet} from "@angular/material/chips";
import {MatBadge} from "@angular/material/badge";
import {ContractAutocompleteComponent} from "@app/admin/contract-autocomplete/contract-autocomplete.component";

@Component({
  selector: 'app-right-form',
  standalone: true,
  imports: [
    MatCheckbox,
    MatSelect,
    NgClass,
    MatOption,
    RightPipe,
    FormsModule,
    RightTableComponent,
    ReactiveFormsModule,
    MatLabel,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatInput,
    MatExpansionModule,
    MatFormField,
    MatChipSet,
    MatChip,
    MatBadge,
    ContractAutocompleteComponent
  ],
  templateUrl: './right-form.component.html',
  styleUrl: './right-form.component.scss'
})
export class RightFormComponent implements OnInit {

  @Input() currentRules?: Rule[];
  @Output() addRule: EventEmitter<any> = new EventEmitter;
  @Input() rightsType!: string[];
  store = inject(ConstStore);
  fb = inject(FormBuilder);
  selectedRegion?: number;
  form!: FormGroup;
  selectedProfile?: Profile;
  all: boolean = false;
  step = signal(-1);

  ngOnInit(): void {
    this.selectedRegion = this.store.regions()[0].id;
    this.selectedProfile = this.store.profileWithoutAdmin()[0];
    this.form = this.fb.group({
      profileId: new FormControl(this.selectedProfile.id),
      activities: new FormControl([]),
      agencies: new FormControl([]),
      contracts: new FormControl([]),
      type: new FormControl(this.rightsType?.length === 1 ? this.rightsType[0] : undefined, [Validators.required]),
      all: new FormControl(false),
    })
    this.currentRules = this.currentRules || [];
  }

  changeProfile(profile: Profile) {
    this.selectedProfile = profile;
    this.form.patchValue({profileId: profile.id});
  }

  selectAll(event: any) {
    this.all = event.checked;
  }

  selectActivity(event: any, activityId?: number) {
    const activities = event.checked ? this.form.get('activities')?.value.concat(activityId) : this.form.get('activities')?.value?.filter((a: Activity) => a !== activityId);
    this.form.patchValue({activities});
  }

  selectedRegionChange(selectChange: MatSelectChange) {
    this.selectedRegion = selectChange.value;
  }

  selectAgence(event: any, agenceId?: number) {
    const agencies = event.checked ? this.form.get('agencies')?.value.concat(agenceId) : this.form.get('agencies')?.value?.filter((a: Agency) => a !== agenceId);
    this.form.patchValue({agencies});
  }

  setRules() {
    this.currentRules?.push(JSON.parse(JSON.stringify(this.form.getRawValue())));
    this.addRule.emit(this.currentRules);
  }

  deleteRule(index: number) {
    this.currentRules?.splice(index, 1);
  }
}
