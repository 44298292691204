import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Observable, pipe, switchMap, tap } from 'rxjs';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  ActivitiesService,
  Activity,
  AgenciesService,
  Agency,
  Contract,
  ContractsService,
  EntitiesService,
  Entity,
  Profile,
  ProfilesService,
  Region,
  RegionsService,
  User,
  UsersService,
  UserService,
} from '@api';
import { computed, inject } from '@angular/core';

export interface ConstStateInterface {
  regions: Region[];
  agencies: Agency[];
  activities: Activity[];
  entities: Entity[];
  profiles: Profile[];
  contract: Contract;
  user: User;
  loadUser: boolean;
}

export const ConstStore = signalStore(
  withState<ConstStateInterface>({
    regions: [],
    agencies: [],
    activities: [],
    entities: [],
    profiles: [],
    user: { id: -1, contracts: [] },
    contract: { id: -1, name: '', code: '', legalEntityId: -1 },
    loadUser: true,
  }),
  withComputed(({ profiles, user, contract, entities }) => ({
    profileWithoutAdmin: computed(() =>
      profiles().filter((p) => p.ref !== user().profileRef)
    ),
    legalEntityLogo: computed(
      () =>
        entities().find((entity) => entity.id === contract().legalEntityId)
          ?.uuid
    ),
  })),
  withMethods(
    (
      store,
      agenciesService = inject(AgenciesService),
      activitiesService = inject(ActivitiesService),
      regionsService = inject(RegionsService),
      entityService = inject(EntitiesService),
      contractsService = inject(ContractsService),
      usersService = inject(UsersService),
      userService = inject(UserService),
      profilesService = inject(ProfilesService)
    ) => ({
      getRegionById(id: number): Region | undefined {
        return store
          .regions()
          .find((r) => r.id === this.getAgencyById(id)?.regionId);
      },
      getAgencyById(id: number): Agency | undefined {
        return store.agencies().find((r) => r.id === id);
      },
      getAgenciesByRegionId(id?: number): Agency[] | undefined {
        return store.agencies().filter((r) => r.regionId === id);
      },
      getActivityById(id: number): Region | undefined {
        return store.activities().find((r) => r.id === id);
      },
      getProfilById(id: number): Profile | undefined {
        return store.profiles().find((p) => p.id === id);
      },
      getProfilByKeycloakTag(keycloakTag?: string): Profile | undefined {
        return store.profiles().find((p) => p.ref === keycloakTag);
      },
      getEntityById(id: number): Entity | undefined {
        return store.entities().find((p) => p.id === id);
      },
      setCurrentContract(contract: Contract) {
        patchState(store, { contract });
      },
      loadAgencies: rxMethod<void>(
        pipe(
          switchMap(() => {
            return agenciesService.getAgencies().pipe(
              tap((agencies) => {
                patchState(store, { agencies });
              })
            );
          })
        )
      ),
      loadUserContracts(): Observable<User> {
        patchState(store, { loadUser: true });
        return userService.getUser().pipe(
          tap((user) => {
            if (user?.contracts[0]) {
              patchState(store, { contract: user?.contracts[0] });
            }
            patchState(store, { user, loadUser: false });
          })
        );
      },
      loadActivities: rxMethod<void>(
        pipe(
          switchMap(() => {
            return activitiesService.getActivities().pipe(
              tap((activities) => {
                patchState(store, { activities });
              })
            );
          })
        )
      ),
      loadRegions: rxMethod<void>(
        pipe(
          switchMap(() => {
            return regionsService.getRegions().pipe(
              tap((regions) => {
                patchState(store, { regions });
              })
            );
          })
        )
      ),
      loadEntity: rxMethod<void>(
        pipe(
          switchMap(() => {
            return entityService.getEntities().pipe(
              tap((entities: any[]) => {
                patchState(store, { entities });
              })
            );
          })
        )
      ),
      loadProfiles: rxMethod<void>(
        pipe(
          switchMap(() => {
            return profilesService.getProfiles().pipe(
              tap((profiles: Profile[]) => {
                patchState(store, { profiles });
              })
            );
          })
        )
      ),
    })
  ),
  withHooks({
    onInit(store) {
      store.loadAgencies();
      store.loadRegions();
      store.loadActivities();
      store.loadEntity();
      store.loadProfiles();
    },
  })
);
