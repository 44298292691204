import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {RightDirective} from "@app/directives/right.directive";
import {Link, News, Rule} from "@api";
import {ConstStore} from "@store/const.store";

@Component({
  selector: 'app-action-button-bar',
  standalone: true,
  imports: [
    MatIcon,
    RightDirective
  ],
  templateUrl: './action-button-bar.component.html',
  styleUrl: './action-button-bar.component.scss'
})
export class ActionButtonBarComponent {
  constStore = inject(ConstStore);
  @Input() element!: any;
  rightType = Rule.TypeEnum;
  @Output() viewEvent = new EventEmitter;
  @Output() downloadEvent = new EventEmitter;
  @Output() editEvent = new EventEmitter;
  @Output() deleteEvent = new EventEmitter;

  view(element:Element | Link | News){
    this.viewEvent.emit(element);
  }
  download(element:Element | Link | News){
    this.downloadEvent.emit(element);
  }
  edit(element:Element | Link | News){
    this.editEvent.emit(element);
  }
  delete(element:Element | Link | News){
    this.deleteEvent.emit(element);
  }
}
