@for (r of rightsType; track r; let last = $last) {
  <div class="font-bold">{{ r | right }}</div>
  <div class="my-3">
    @for (rule of rules; track rule; let i = $index) {
      @if (rule.type === r && rule.profileId === profileId) {
        <div class="my-2 flex items-center">
          @if (rule.all) {
            Tous les contrats
          } @else {
            @for (activity of rule.activities; track activity; let last = $last) {
              {{ activity | activity }}
              @if (!last) {
                ,
              }
            }
            @for (agenceId of rule.agencies; track agenceId; let last = $last, first = $first) {
              @if (first && rule.activities.length > 0) {
                -
              }
              {{ agenceId | agence }}
              @if (!last) {
                ,
              }
            }
            @for (contract of rule.contracts; track contract; let last = $last, first = $first) {
              @if (first && (rule.activities.length > 0 || rule.agencies.length > 0)) {
                -
              }
              {{ contract.name }}
              @if (!last) {
                ,
              }
            }
          }
          <button (click)="deleteRight(i)">
            <img src="/icons/delete.svg" class="ml-2"/>
          </button>
        </div>
      }
      @if (!last) {
        <hr class="border-gray-100"/>
      }
    }
  </div>
}
