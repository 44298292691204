import {Pipe, PipeTransform} from '@angular/core';
import {Rule} from "@api";

@Pipe({
  name: 'right',
  standalone: true
})
export class RightPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): any {
    switch (Rule.TypeEnum[value as keyof typeof Rule.TypeEnum]) {
      case Rule.TypeEnum.CONSULTATION:
        return 'Consultation';
      case Rule.TypeEnum.DOWNLOAD:
        return 'Téléchargement';
      case Rule.TypeEnum.UPLOAD:
        return 'Dépose';
      case Rule.TypeEnum.VISIBILITY:
        return 'Visibilité';
    }
  }

}
