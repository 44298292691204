<div class="flex flex-col justify-between">

  <div class="flex flex-col gap-1 items-center text-2xl m-5">
    Créer un contrat
  </div>
  <mat-dialog-content>
    <div class="overflow-auto max-h-screen/2 bg-white shadow-sm rounded-md border-2 p-5">

      <form [formGroup]="form">
        <div class="m-5 flex flex-col gap-5 items-center">
          <div class="flex flex-row gap-5 flex-wrap justify-center">
            <mat-form-field class="w-2/5">
              <mat-label>Code</mat-label>
              <input
                matInput
                type="text"
                formControlName="code">
              @if (form.get('code')?.hasError('required')) {
                <mat-error>Ce champs est obligatoire</mat-error>
              }
            </mat-form-field>
            <mat-form-field class="w-2/5">
              <mat-label>Nom du contrat</mat-label>
              <input
                matInput
                type="text"
                formControlName="name">
              @if (form.get('name')?.hasError('required')) {
                <mat-error>Ce champs est obligatoire</mat-error>
              }
            </mat-form-field>

            <mat-form-field class="w-2/5">
              <mat-label>Sélectionnez la region</mat-label>
              <mat-select
                [value]="selectedRegionId"
                (selectionChange)="selectRegion($event)">
                @for (region of constStore.regions(); track region) {
                  <mat-option [value]="region.id">{{ region.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            @if (selectedRegionId) {
              <mat-form-field class="w-2/5">
                <mat-label>Sélectionnez l'agence</mat-label>
                <mat-select formControlName="agencyId">
                  @for (agence of constStore.getAgenciesByRegionId(selectedRegionId); track agence) {
                    <mat-option [value]="agence.id">{{ agence.name }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }@else{
              <div class="w-2/5"></div>
            }

            <mat-form-field class="w-2/5">
              <mat-label>Sélectionnez l'activité</mat-label>
              <mat-select formControlName="activityId">
                @for (activity of constStore.activities(); track activity) {
                  <mat-option [value]="activity.id">{{ activity.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-2/5">
              <mat-label>Sélectionnez l'entité</mat-label>
              <mat-select formControlName="legalEntityId">
                @for (entity of constStore.entities(); track entity) {
                  <mat-option [value]="entity.id">{{ entity.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex flex-row gap-5 flex-wrap">
            <div
              class="relative justify-between w-52 border-2 rounded-2xl bg-secondary/30 p-4 text-center ring-1 ring-inset hover:border-secondary hover:border-2 lg:flex lg:flex-col">
              <p class="text-gray-800 h-12">Logo</p>
              @if (form.get('logo')?.value) {
                <span class="absolute right-2.5 top-2.5 text-red-600 cursor-pointer font-extrabold"
                      (click)="removeLogo()">X</span>
                <img class="max-w-24 mx-auto max-h-16"
                     [src]="logo ? logo : '/api/image/'+contract()?.logo"/>
              } @else {
                <ngx-file-drop accept=".png,.jpeg,.jpg" dropZoneLabel="Drop files here"
                               (onFileDrop)="droppedLogo($event)"
                               dropZoneClassName="dropZoneClassName" contentClassName="contentClass">
                  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

                    <button type="button" (click)="openFileSelector()" class="flex flex-col items-center">
                      <img src="/icons/add-file.svg" class="max-h-16"/>
                      <div class="text-black text-md">Ajouter une image</div>
                    </button>
                  </ng-template>
                </ngx-file-drop>
              }
            </div>
            <div
              class="relative justify-between w-52 border-2 rounded-2xl bg-secondary/30 p-4 text-center ring-1 ring-inset hover:border-secondary hover:border-2 lg:flex lg:flex-col">
              <p class="text-gray-800 h-12">Entête</p>
              @if (form.get('wallpaper')?.value) {
                <span class="absolute right-2.5 top-2.5 text-red-600 cursor-pointer font-extrabold"
                      (click)="removeWallpaper()">X</span>
                <img class="max-w-24 mx-auto max-h-16"
                     [src]="wallpaper ? wallpaper : '/api/image/'+contract()?.wallpaper"/>
              } @else {
                <ngx-file-drop accept=".png,.jpeg,.jpg" dropZoneLabel="Drop files here"
                               (onFileDrop)="droppedWallpaper($event)"
                               dropZoneClassName="dropZoneClassName" contentClassName="contentClass">
                  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

                    <button type="button" (click)="openFileSelector()" class="flex flex-col items-center">
                      <img src="/icons/add-file.svg" class="max-h-16"/>
                      <div class="text-black text-md">Ajouter une image</div>
                    </button>
                  </ng-template>
                </ngx-file-drop>
              }
            </div>
          </div>
        </div>
      </form>

    </div>
  </mat-dialog-content>
  <div class="flex flex-row gap-1 items-center justify-evenly m-5">
    <button class="rounded-2xl bg-gray-300 text-black px-5 py-2 w-fit hover:bg-gray-400" (click)="cancel()">Annuler
    </button>
    <button class="rounded-2xl bg-secondary text-white disabled:bg-secondary/40 px-5 py-2 w-fit"
            [disabled]="!form.valid" (click)="validate()">
      Valider
    </button>
  </div>

</div>
