<nav class="bg-primary">
  <div class="px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="flex flex-1 h-16 justify-center sm:justify-start">
        <div class="flex flex-shrink-0 items-center">
          <img
            class="h-12 w-auto hidden sm:block"
            [src]="'/api/image/' + constStore.legalEntityLogo()"
          />
        </div>
      </div>
      <div class="flex sm:justify-end pr-2 sm:ml-6 sm:pr-0">
        @if (constStore.user().contracts.length > 1) {
        <mat-select
          class="!text-gray-500 !bg-gray-100 !w-80 !rounded-md !mr-3 !px-3 !flex"
          placeholder="Sélectionnez la region"
          (selectionChange)="selectContract($event)"
          [formControl]="contractControl"
          panelClass="!text-gray-500 !bg-gray-200 !mr-3"
        >
          @for (contract of constStore.user().contracts | sort:'name'; track
          contract) {
          <mat-option [value]="contract.id">{{ contract.name }}</mat-option>
          }
        </mat-select>
        }
        <!--
        <button
          type="button"
          class="relative rounded-full bg-primary p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
        >
          <span class="absolute -inset-1.5"></span>
          <span class="sr-only">View notifications</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
            />
          </svg>
        </button>
        -->
        <!-- Profile dropdown -->
        <div class="flex flex-row ml-3">
          @if (constStore.profiles().length > 0) {
          <div
            class="max-lg:hidden flex flex-col text-white text-xs m-2 text-right"
          >
            <span>{{ userService.username }}</span>
            <span>{{ constStore.user().profile | uppercase }}</span>
          </div>
          }
          <div class="min-w-fit items-center flex flex-row">
            <button
              type="button"
              class="relative flex rounded-full bg-primary text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
              [matMenuTriggerFor]="menu"
            >
              <div
                class="flex items-center justify-center w-8 h-8 rounded-full bg-secondary text-primary text-md font-bold"
              >
                {{ userService.username | slice : 0 : 1 }}
              </div>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="logout()">Déconnexion</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
