<div class="m-5 flex flex-col gap-5">
  @if (widget.documents.length === 0) {
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                   dropZoneClassName="dropZoneClassName" contentClassName="contentClass">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

        <button type="button" (click)="openFileSelector()" class="flex flex-col items-center">
          <img src="/icons/add-file.svg" class="h-28"/>
          <div class="text-black text-xl font-bold">Ajouter des documents</div>
        </button>
      </ng-template>
    </ngx-file-drop>
  } @else {
    <div class="flex flex-row flex-wrap gap-5">
      @for (doc of widget.documents; track doc; let i = $index) {
        <div
          class="relative justify-between w-52 border-4 cursor-pointer rounded-2xl bg-secondary/30 p-4 text-center lg:flex lg:flex-col border-white hover:border-secondary "
        >
          @if (!modifyDoc) {
            <span class="absolute right-2.5 top-2.5 text-red-600 cursor-pointer font-extrabold"
                  (click)="removeFile(i)">X</span>
          }
          <p class="text-gray-800 h-12 break-words line-clamp-2">{{ doc.name | uppercase }}</p>
          <img class="max-w-24 mx-auto max-h-16"
               src="/icons/doc.svg"/>
        </div>
      }
      @if (!modifyDoc) {
        <div
          class="justify-between w-52 border-4 cursor-pointer rounded-2xl bg-secondary/30 p-4 text-center lg:flex lg:flex-col border-white hover:border-secondary "
        >
          <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                         dropZoneClassName="dropZoneCardClassName" contentClassName="contentCardClass">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

              <button type="button" (click)="openFileSelector()" class="flex flex-col items-center">
                <img src="/icons/add-file.svg" class="max-h-16"/>
                <div class="text-black text-md">Ajouter des documents</div>
              </button>
            </ng-template>
          </ngx-file-drop>
        </div>
      }
    </div>
  }
</div>
