<div class="flex flex-col mx-3 mt-5 gap-3">

  <div class="flex-1 flex flex-row gap-5 items-center">
    <span class="font-bold">Profils :</span>
    @for (profile of constStore.profileWithoutAdmin(); track profile) {
      <button
        class="p-1  border-2 border-secondary rounded-md "
        [ngClass]="selectedProfile.id===profile.id ? 'bg-secondary text-white' : 'bg-white text-secondary'"
        (click)="selectProfile(profile)"
      >
        {{ profile.name }}
      </button>
    }
  </div>
  <table class="flex-1 mb-5 shadow">
    <thead class="text-white first:rounded-tl-md last:rounded-tr-md">
    <td class="p-2">
      Type de droits
    </td>
    <td class="p-2">
      {{ selectedProfile.name }}
    </td>
    </thead>
    @for (r of rightsType; track r; let last = $last) {
      <tr class="border-b-gray-100 border-b-2 p-2">
        <td class="font-bold py-2">
          {{ r | right }}
        </td>
        <td>
          <ul>
            @for (rule of ruleToDisplay; track rule) {
              @if(rule.type === r){
                <li>
                  @for(activity of rule.activities;track activity; let last = $last){
                    {{activity | activity}}
                    @if(!last){
                      ,
                    }
                  }
                  @for(agency of rule.agencies;track agency; let last = $last;let first = $first){
                    @if(first && rule.activities.length >0){
                      -
                    }
                    {{agency | agence}}
                    @if(!last){
                      ,
                    }
                  }

                  @for(contract of rule.contracts;track contract; let last = $last,first = $first){
                    @if(first && (rule.agencies.length >0 || rule.activities.length>0)){
                      -
                    }
                    {{contract.name }}
                    @if(!last){
                      ,
                    }
                  }
                </li>
              }
            }
          </ul>
        </td>
      </tr>
    }
  </table>

</div>
