import { Component, inject, model, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { Contract } from '@api';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { UpperCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatOption } from '@angular/material/core';
import {
  MatError,
  MatFormField,
  MatLabel,
  MatSelect,
} from '@angular/material/select';
import { ConstStore } from '@store/const.store';
import { MatInput } from '@angular/material/input';

interface DialogData {
  contract?: Contract;
}

@Component({
  selector: 'app-create-contract-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    ReactiveFormsModule,
    NgxFileDropModule,
    UpperCasePipe,
    MatOption,
    MatSelect,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
  ],
  templateUrl: './create-contract-dialog.component.html',
  styleUrl: './create-contract-dialog.component.scss',
})
export class CreateContractDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<CreateContractDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  contract = model(this.data?.contract);
  form!: FormGroup;
  fb = inject(FormBuilder);
  wallpaper: any;
  logo: any;
  constStore = inject(ConstStore);
  selectedRegionId?: number;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      id: new FormControl(this.contract()?.id),
      name: new FormControl(this.contract()?.name, [Validators.required]),
      code: new FormControl(this.contract()?.code, [Validators.required]),
      agencyId: new FormControl(this.contract()?.agencyId, [
        Validators.required,
      ]),
      activityId: new FormControl(this.contract()?.activityId, [
        Validators.required,
      ]),
      legalEntityId: new FormControl(this.contract()?.legalEntityId, [
        Validators.required,
      ]),
      logo: new FormControl(this.contract()?.logo, [Validators.required]),
      wallpaper: new FormControl(this.contract()?.logo, [Validators.required]),
      wallpaperBlob: new FormControl(),
      logoBlob: new FormControl(),
    });
    if (this.contract()?.agencyId) {
      this.selectedRegionId = this.constStore.getRegionById(
        this.contract()?.agencyId || 0
      )?.id;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  validate() {
    this.dialogRef.close({ contract: this.form.getRawValue() });
  }

  selectRegion(event: any) {
    this.selectedRegionId = event.value;
  }

  public droppedWallpaper(files: NgxFileDropEntry[]) {
    // Is it a file?
    if (
      files[0].fileEntry.isFile &&
      files[0].fileEntry.name.match(`^.*\\.(jpg|jpeg|png)$`)
    ) {
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        let objectURL = URL.createObjectURL(file);
        this.wallpaper = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.form.patchValue({ wallpaper: file.name, wallpaperBlob: file });
      });
    }
  }

  public droppedLogo(files: NgxFileDropEntry[]) {
    // Is it a file?
    if (
      files[0].fileEntry.isFile &&
      files[0].fileEntry.name.match(`^.*\\.(jpg|jpeg|png)$`)
    ) {
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        let objectURL = URL.createObjectURL(file);
        this.logo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.form.patchValue({ logo: file.name, logoBlob: file });
      });
    }
  }

  removeLogo() {
    this.form.patchValue({ logo: undefined, logoBlob: undefined });
  }

  removeWallpaper() {
    this.form.patchValue({ wallpaper: undefined, wallpaperBlob: undefined });
  }
}
