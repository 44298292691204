<div class="flex flex-col gap-1 items-center text-2xl m-5">
  {{ data.content }}
</div>

<div class="flex flex-row gap-1 items-center justify-evenly m-5">
  <button class="rounded-2xl bg-gray-100 text-black px-5 py-2 w-fit hover:bg-gray-200" (click)="cancel()">
    Annuler
  </button>
  <button class="rounded-2xl bg-secondary text-white disabled:bg-secondary/40 px-5 py-2 w-fit"
          (click)="validate()">
    Valider
  </button>
</div>
