import { Component, inject } from '@angular/core';
import { FolderDetailComponent } from './folder-detail/folder-detail.component';
import { UserService } from '../../services/user/user.service';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { Element, Rule } from '@api';
import { DocumentWithBlob, FoldersStore } from '@app/store/folders.store';
import { MatIcon } from '@angular/material/icon';
import { TreeComponent } from '@app/shared/folders/tree/tree.component';
import { BreadcrumbComponent } from '@app/shared/folders/breadcrumb/breadcrumb.component';
import { DocumentDetailComponent } from '@app/shared/folders/document-detail/document-detail.component';
import { FolderCardComponent } from '@app/shared/folders/folder-card/folder-card.component';
import { IsAdminDirective } from '@app/directives/is-admin.directive';
import { ConstStore } from '@store/const.store';
import { MatDialog } from '@angular/material/dialog';
import { RightDirective } from '@app/directives/right.directive';
import { AddDocumentDialogComponent } from '@app/shared/add-document-dialog/add-document-dialog.component';
import TypeEnum = Rule.TypeEnum;

@Component({
  selector: 'app-folders',
  standalone: true,
  imports: [
    FolderDetailComponent,
    NgClass,
    MatIcon,
    TreeComponent,
    BreadcrumbComponent,
    DocumentDetailComponent,
    FolderCardComponent,
    IsAdminDirective,
    RightDirective,
  ],
  templateUrl: './folders.component.html',
  styleUrl: './folders.component.scss',
})
export class FoldersComponent {
  folderDisplay: boolean = true;
  listView: boolean = false;
  store = inject(FoldersStore);
  constStore = inject(ConstStore);
  selectedElement?: Element;
  protected readonly Rule = Rule;

  constructor(
    private dialog: MatDialog,
    protected userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.listView = this.constStore.user().admin ? true : false;
  }

  closeDetail() {
    this.selectedElement = undefined;
  }

  select(element: Element | undefined) {
    this.selectedElement = element;
  }

  addDocuments() {
    const dialogRef = this.dialog.open(AddDocumentDialogComponent, {
      minWidth: '70vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        result?.widget?.documents?.forEach((document: DocumentWithBlob) =>
          this.store.addDocument(document, [
            {
              profileId: this.constStore.user().profileId,
              activities: [],
              agencies: [],
              contracts: [this.constStore.contract()],
              type: TypeEnum.DOWNLOAD,
            },
          ])
        );
      }
    });
  }
}
