import {Component, inject, model} from '@angular/core';
import {
  CreateDocumentDialogComponent
} from "@app/shared/create-widget-dialog/create-document-dialog/create-document-dialog.component";
import {
  CreateFolderDialogComponent
} from "@app/shared/create-widget-dialog/create-folder-dialog/create-folder-dialog.component";
import {
  CreateLinkDialogComponent
} from "@app/shared/create-widget-dialog/create-link-dialog/create-link-dialog.component";
import {
  CreateNewsDialogComponent
} from "@app/shared/create-widget-dialog/create-news-dialog/create-news-dialog.component";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {RightFormComponent} from "@app/shared/right/right-form/right-form.component";
import {News} from "@api";
import {DatePipe, UpperCasePipe} from "@angular/common";

@Component({
  selector: 'app-news-detail-dialog',
  standalone: true,
  imports: [
    CreateDocumentDialogComponent,
    CreateFolderDialogComponent,
    CreateLinkDialogComponent,
    CreateNewsDialogComponent,
    MatDialogContent,
    MatStep,
    MatStepLabel,
    MatStepper,
    RightFormComponent,
    DatePipe,
    UpperCasePipe
  ],
  templateUrl: './news-detail-dialog.component.html',
  styleUrl: './news-detail-dialog.component.scss'
})
export class NewsDetailDialogComponent {

  readonly dialogRef = inject(MatDialogRef<NewsDetailDialogComponent>);
  readonly news = inject<News>(MAT_DIALOG_DATA);


  cancel(): void {
    this.dialogRef.close();
  }
}
