import {inject, Pipe, PipeTransform} from '@angular/core';
import {ConstStore} from "@store/const.store";

@Pipe({
  name: 'activity',
  standalone: true
})
export class ActivityPipe implements PipeTransform {

  store = inject(ConstStore);

  transform(value: number, ...args: unknown[]): string|undefined {
    return this.store.getActivityById(value)?.name;
  }

}
