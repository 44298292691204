import {Component, inject} from '@angular/core';
import {FoldersComponent} from "@app/shared/folders/folders.component";
import {UserService} from "@app/services/user/user.service";
import {MatDialog} from "@angular/material/dialog";
import {DocumentWithBlob, FoldersStore} from "@store/folders.store";
import {CreateWidgetDialogComponent, WidgetType} from "@app/shared/create-widget-dialog/create-widget-dialog.component";
import {IsAdminDirective} from "@app/directives/is-admin.directive";
import {Rule} from "@api";

@Component({
  selector: 'app-folders-table',
  standalone: true,
  imports: [
    FoldersComponent,
    CreateWidgetDialogComponent,
    IsAdminDirective
  ],
  providers : [
    FoldersStore
  ],
  templateUrl: './folders-table.component.html',
  styleUrl: './folders-table.component.scss',
  host: {class: 'p-5 block'}
})
export class FoldersTableComponent {

  store = inject(FoldersStore);

  constructor(
    protected userService: UserService,
    private dialog: MatDialog) {

  }

  createFolder() {
    const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
      minWidth: '70vw',

      data: {widget: {}, type: WidgetType.FOLDER, rightsType :  Object.keys(Rule.TypeEnum).filter(r=>r!==Rule.TypeEnum.DOWNLOAD)}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.store.addFolder(result.widget, result.widget.blob);
      }
    });
  }

  addDocuments() {
    const dialogRef = this.dialog.open(CreateWidgetDialogComponent, {
      minWidth: '70vw',

      data: {widget: {documents: []}, type: WidgetType.DOCUMENT,rightsType :  Object.keys(Rule.TypeEnum).filter(r=>r!==Rule.TypeEnum.UPLOAD)}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        result?.widget?.documents?.forEach((document: DocumentWithBlob) => this.store.addDocument(document,result.widget?.rules));
      }
    });
  }

}
