<div class="font-bold text-2xl mb-5 ml-3 w-fit">
  Actualités
  <hr class="ml-8 border-2 border-tertiary pr-24" />
</div>
<button
  class="rounded-3xl bg-secondary text-white font-semibold py-2 px-8"
  (click)="addNews()"
>
  Créer une actualité
</button>

<div class="mx-16 my-5 !min-w-fit">
  @if (!store.loadingNews() && store.total()===0){ Aucune actualité } @else {
  <table
    matSort
    mat-table
    [dataSource]="store.news()"
    (matSortChange)="sortData()"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        mat-sort-header="name"
        class="rounded-tl-xl"
        *matHeaderCellDef
      >
        Sujet
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="createDate">
      <th mat-header-cell mat-sort-header="createDate" *matHeaderCellDef>
        Date de publication
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.createDate | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="button">
      <th mat-header-cell *matHeaderCellDef class="rounded-tr-xl">Action</th>
      <td mat-cell *matCellDef="let element">
        <app-action-button-bar
          [element]="element"
          (editEvent)="updateNews($event)"
          (deleteEvent)="deleteNews($event)"
        ></app-action-button-bar>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    @if(store.loadingNews()){
    <div
      role="status"
      class="my-5 mx-16 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
    >
      <div class="flex items-center justify-between">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
          ></div>
          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
    </div>

    } @else{
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="select(row)"
    ></tr>
    }
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 50]"
    (page)="handlePageEvent()"
    [pageIndex]="store.page() - 1"
    [pageSize]="store.pageSize()"
    [length]="store.total()"
    class="rounded-b-xl"
    showFirstLastButtons
  >
  </mat-paginator>
  }
</div>
