import {Routes} from '@angular/router';
import {HomeComponent} from "./community/home/home.component";
import {AdminGuard} from "./admin/admin.guard";
import {FoldersComponent} from "./shared/folders/folders.component";
import {NewsTableComponent} from "./admin/news-table/news-table.component";
import {LinksTableComponent} from "./admin/links-table/links-table.component";
import {ContractsComponent} from "./admin/contracts/contracts.component";
import {UsersComponent} from "./admin/users/users.component";
import {FoldersTableComponent} from "@app/admin/folders-table/folders-table.component";
import {environment} from "../environments/environment";

export enum MENU {
  NEWS ="NEWS",
  WIDGETS = "WIDGETS",
  LINKS = "LINKS",
  CONTRACTS = "CONTRACTS",
  USERS = "USERS"
}

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    children: [
      {
        path: 'widgets',
        component: FoldersTableComponent,
      },
      {
        path: 'news',
        component: NewsTableComponent,
      },
      {
        path: 'links',
        component: LinksTableComponent,

      },
      {
        path: 'contracts',
        component: ContractsComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: '',
        redirectTo : 'widgets',
        pathMatch : 'prefix'
      },
    ]
  },
  {
    path: '',
    redirectTo : '/admin/widgets',
    pathMatch : 'full'
  },
  {
    path: '**',
    component: HomeComponent
  },
];


